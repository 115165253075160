export const Services = () => {
    return (
    <div>
        <div className="container mt-3">
            <div className="text-center">
                <div className="card">
                    <div className="card-header ">
                        <strong>Services</strong>
                    </div>
                </div>
            </div>
        </div>
          
    <div>
        <div className="container mt-3">
            <div> 
            <div id="main">
			<div className="container">
				<div className="">
                    <div className="span8 skel-cell-important entry-content">
						<section>
                                                  
                                                                                                        <p><h3>a. - Main Functions </h3>
<h2> A ) - A.Gs Function</h2>
<ul>
<li>Pay fixation, Service Verification and issuance of Pay slips of Gazetted Officers.</li>
<li>Issuance of Pension Payment Order (Fresh &amp; Revised) and Post Audit of Pension Paid Vouchers.</li>
<li>Maintenance of G.P.Fund Accounts.</li>
<li>Maintenance of Broadsheet for Loans and Advances and issuance of No Demand Certificate (NDC)</li>
<li>Exchange Account. (Civil &amp; Defense)</li>
<li>Issuance of Letter of Credit to Cost Centers (GPOs)</li>
<li>Issuance of Annual Profit Statement on Ordinary Saving Bank Accounts.</li>
</ul>

<h2> B ) - ACCOUNTING &amp; POST AUDIT OF </h2>
   <h3>i) - Core Business</h3>
    <ul>
    <li>Pakistan Postal Order (PPO)</li>
    <li>Postal Draft Service (PDS)</li>
    <li>Money Orders</li>
    <ul>
    <li>Ordinary</li>
	<li>Urgent</li>
	<li>Fax</li>
	<li>Foreign</li>
    </ul>
    </ul>
<h3>ii) - Agency Business</h3>
<ul>
<li>On behalf of Federal Govt.
	<ul>
	<li>National Savings Schemes, </li>
	<li>Military &amp; FC, CDA Pension, </li>
	<li>Pakistan Bait-ul-Mal, B.I.S.P.</li>
	<li>Collection of custom duty, Sales Tax etc.</li>
	</ul>
</li>
	
<li>On behalf of Provincial Government
<ul>
	<li>Motor Vehicles Tax, </li>
	<li>Sale of Provincial Tax Stamps – Arms License &amp; Driving License </li>
	</ul>
</li>

	<li>On behalf of Corporations
<ul>
	<li>Collection of Electricity, PTCL, Sui Gas and Water charges Bills, </li>
	<li>PTCL Pension Payment)</li>
</ul>
</li>
</ul>
<p>The Director Accounts is performing dual duties i.e. accounting as well as Post auditing.</p>


<h3>b. - Organizational Setup </h3><span>
DAPPO Lahore is a professional organization comprising of Accounts Officers, Assistant Accounts Officers and Auditors at the operational level and Deputy Directors Accounts at the supervisory level. Organization chart follows.</span><div ><br></br></div><div><h3>c - Reporting System </h3>
<h3>d - Accounting System </h3>
<ul>
<li>Cash Account and supporting vouchers are received from 83 GPOs.</li>
<li>Two streams working (processing of Cash Account and Ledger Keeping)</li>
<li>PA sections examine the Cash Accounts viz a viz supporting vouchers, complete the classNameification of unclassNameified items, cross check the balances, work out the revenue from postage stamps, Franking machine and parcel postage.</li>
<li>In case of discrepancy, observations are raised and conveyed to the respective GPO for reconciliation.</li>
<li>After posting of figures in ClassNameified Abstract, the PA sections refer the same to the PIFRA Lab.</li>
<li>Punching of ClassNameified figures in SAP/R3 System.</li>
<li>Agreement of Receipt and Payment figures at Parking level.</li> 
<li>Posting of agreed data on Federal Server.</li>
<li>Generation of Reports. </li>
<li>20  Sections are working on Ledger keeping </li>
<li>Postal activities vouchers are posted in Broad Sheets/Ledgers.</li>
<li>The detailed head-wise receipt/payment are arrived at.</li>
<li>Detailed head-wise figures are compared with the corresponding figures of the Cash Account(First stream) </li>
<li>Discrepancy in the figures arrived through the Two streams is pointed out and referred to the respective GPO for reconciliation.</li>
<li>The impact of the reconciliation when arrived at, is given to the respective account. </li>
</ul></div></p>
                                                </section>
                                        					</div>
                                    
                                    
                                    
                                    
                                        
  

            </div>
            <br></br><br></br>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
  
    
    )
  }
  
  
  