import React, { useState } from "react";
import axios from "axios";
// import { userIdContext } from "../App";
// import { useContext } from "react";

function CarouselForm() {

  //const {userId} = useContext(userIdContext);

  const userId = localStorage.getItem("userId");

  const [carouselResult, setCarouselResult] = useState("");

  const [carouselName, setCarouselName] = useState("");

  const [carouselLink, setCarouselLink] = useState("");

  const [imageFile, setImageFile] = useState(null);

  const handleCarouselNameChange = (event) => {
    setCarouselName(event.target.value);
  };

  const handleCarouselLinkChange = (event) => {
    setCarouselLink(event.target.value);
  };

  const handleImageChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData();
    //const userId = formData.get("cid");

    formData.append("carouselName", carouselName);
    formData.append("image", imageFile);
    formData.append("carousellink", carouselLink);
    formData.append("id", userId);

    try {
      const response = await axios.post(
        "https://dappo.gov.pk/Dappo/carousel_api/insertCarousel_api.php/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setCarouselName("");

      setCarouselLink("");

      setImageFile([null]);

      //console.log(response.data); // You can do something with the response data here
      setCarouselResult(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary mb-3"
        data-bs-toggle="modal"
        data-bs-target="#addCarousel"
      >
        Add Carousel
      </button>

      <div className="modal" id="addCarousel">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Carousel Image</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={handleFormSubmit}>
                <input
                  type="hidden"
                  className="form-control"
                  name="cid"
                  id="cid"
                  value={userId}
                />

                <div className="form-group mb-2">
                  <label htmlFor="">Carousel Name:</label>
                  <input
                    type="text"
                    name="carouselName"
                    id="carouselName"
                    className="form-control"
                    value={carouselName}
                    onChange={handleCarouselNameChange}
                    required
                  />
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="carouselimage">Upload Image:</label>
                  <input
                    type="file"
                    name="carouselimage"
                    id="carouselimage"
                    className="form-control-file"
                    onChange={handleImageChange}
                    required
                  />
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="">Carousel Link:"https://abc.gov.pk"</label>
                  <input
                    type="text"
                    name="carousellink"
                    id="carousellink"
                    className="form-control"
                    value={carouselLink}
                    onChange={handleCarouselLinkChange}
                  />
                </div>

                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>

            <div className="modal-footer">
              <p className="bg-danger">{carouselResult}</p>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(CarouselForm);
