import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import { Home } from './pages/home';
import { Downloads } from './pages/Downloads';
import { Contact } from './pages/Contact';
import {NavBar} from './components/NavBar';
import Header from './components/Header';
import {Footer} from './components/Footer';
import {Services} from './pages/Services';
import {Notification} from './pages/Notification';
import Gallery from './pages/Gallery';
import { Admin } from './pages/admin';

export const userIdContext = React.createContext()


function App() {

  //const contextValue = localStorage.getItem('userName');
  const userId = localStorage.getItem('userId');
  const userName = localStorage.getItem('userName');

  
  return (
    <div>
      <userIdContext.Provider value ={{userId, userName}}>
      <Router>
       <Header />
        <NavBar  />
        <Routes>
          <Route>
            <Route path="/" element = {<Home />}  />
            <Route path="/services" element = {<Services />} />
            <Route path="/notification" element = {<Notification />} />
            <Route path="/gallery" element = {<Gallery />} />
            <Route path="/downloads" element = {<Downloads />} />
            <Route path="/contact" element = {<Contact />} />
            <Route path="/admin" element = {<Admin />} />
            <Route path="*" element = {<h1>404 Page not Found</h1>} />
          </Route>
        </Routes>
      </Router>
      <Footer />
      </userIdContext.Provider>
      </div>
  );
}



export default App;
