import { useState, useEffect } from "react";
import axios from "axios";
import Map from "../components/Map";
export const Contact = () => {


  const [managementNotFound, setManagementNotFound] = useState('');
  const [managementDatas, setManagementDatas] = useState([]);
  const [adminNotFound, setAdminNotFound] = useState('');
  const [administrationDatas, setAdministrationDatas] = useState([]);
  const [focalNotFound, setFocalNotFound] = useState('');
  const [focalDatas, setFocalDatas] = useState([]);

  useEffect(()=>{
    getAllManagement();
    getAllAdmin();
    getAllFocal();

  }, []);

  const getAllManagement = async () => {

    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/contact_api/contactManagementAll_api.php`
    );

    if (res.data.status === false) {
      setManagementNotFound(res.data.message)
    } else {
      setManagementDatas(res.data);
    }
    //console.log(res.data.result);
    //console.log(res.data);
    //setManagementDatas(res.data);
  };

  const getAllAdmin = async () => {

    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/contact_api/contactAdminAll_api.php`
    );

    if (res.data.status === false) {
      setAdminNotFound(res.data.message)
    } else {
      setAdministrationDatas(res.data);
    }
    //console.log(res.data.result);
    //console.log(res.data);
    //setAdministrationDatas(res.data);
  };

  const getAllFocal = async () => {

    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/contact_api/contactFocalAll_api.php`
    );

    if (res.data.status === false) {
      setFocalNotFound(res.data.message)
    } else {
      setFocalDatas(res.data);
    }
    //console.log(res.data.result);
    //console.log(res.data);
    //setFocalDatas(res.data);
  };

  return (
    <>
      <div className="container mt-3">
        <div className="text-center">
          <div className="card">
            <div className="card-header ">
              <strong>CONTACT US</strong>
            </div>
          </div>
          <h2>
            <br></br>DIRECTORATE OF ACCOUNTS,<br></br> PAKISTAN POST OFFICE
            DEPARTMENT. <br></br>
          </h2>

          <p>
            <strong>Mailing address:</strong> <br></br> Office of the
            Directorate of Accounts,<br></br> Pakistan Post Office Department,
            <br></br>4th Floor, Audit Complex, Syed Mouj Darya Road, Lahore.
          </p>
          <p>
            <strong>Phone Number:</strong> +92-42-9921-2020(1)
          </p>
          <p>
            <strong>Fax Number:</strong> +92-42-9921-2023
          </p>
          <p>
            <strong>E-mail:</strong> contact@dappo.gov.pk
          </p>
        </div>
        <div id="accordion">
          <div className="card">
            <div className="card-header">
              <a className="btn" data-bs-toggle="collapse" href="#collapseOne">
                <i class="bi bi-chevron-down"></i> <strong> MANAGEMENT </strong>
              </a>
            </div>
            <div
              id="collapseOne"
              className="collapse show"
              data-bs-parent="#accordion"
            >
              <div className="card-body">
                <div className="table-responsive">

                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Designation</th>
                      <th>Phone</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                  <p className="text-danger">{managementNotFound} </p>
                  {managementDatas.map((managementData) => (
                    <tr key={managementData?.contact_id}>
                      <td>{managementData?.contact_designation}</td>
                      <td>{managementData?.contact_phone}</td>
                      <td>{managementData?.contact_email}</td>
                    </tr>
                    ))}

                    {/* <tr>
                      <td>Director Accounts </td>
                      <td>+92-42-9921-2020(1)</td>
                      <td>dpa@dappo.gov.pk</td>
                      </tr>
                      <tr>
                      <td>Deputy Director (Admin)</td>
                      <td>+92-42-9921-2022</td>
                      <td>dda@dappo.gov.pk</td>
                      </tr>
                      <tr>
                      <td>Deputy Director (Agency)</td>
                      <td>+92-42-9921-1335</td>
                      <td>ddaf@dappo.gov.pk</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <a
                className="collapsed btn"
                data-bs-toggle="collapse"
                href="#collapseTwo"
                >
                <i class="bi bi-chevron-down"></i>
                <strong>ADMINISTRATION</strong>
              </a>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              data-bs-parent="#accordion"
            >
              <div className="card-body">
                <div className="table-responsive">

                <table className="table table-striped table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Designation</th>
                      <th>Phone</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                  <p className="text-danger">{adminNotFound} </p>
                  {administrationDatas.map((administrationData) => (
                    <tr key={administrationData?.contact_id}>
                      <td>{administrationData?.contact_designation}</td>
                      <td>{administrationData?.contact_phone}</td>
                      <td>{administrationData?.contact_email}</td>
                    </tr>
                    ))}
                    {/* <tr>
                      <td>Account Officer (Admin-I)</td>
                      <td>+92-42-9921-2024</td>
                      <td>-</td>
                      </tr>
                      <tr>
                      <td>Account Officer (Admin-II)</td>
                      <td>+92-42-9921-2022</td>
                      <td>-</td>
                      </tr>
                      <tr>
                      <td>Account Officer (Payroll)</td>
                      <td>+92-42-9921-2034</td>
                      <td>-</td>
                      </tr>
                      <tr>
                      <td>Account Officer (Pension)</td>
                      <td>+92-42-9921-2034</td>
                      <td>-</td>
                    </tr>
                    <tr>
                    <td>Account Officer (GPF)</td>
                    <td>+92-42-9921-2025</td>
                    <td>-</td>
                  </tr> */}
                  </tbody>
                </table>
                  </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <a
                className="collapsed btn"
                data-bs-toggle="collapse"
                href="#collapseThree"
              >
                <i class="bi bi-chevron-down"></i>
                <strong>FOCAL PERSONS</strong>
              </a>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              data-bs-parent="#accordion"
            >
              <div className="card-body">
                <div className="table-responsive">

                <table className="table table-striped table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Designation</th>
                      <th>Phone</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                  <p className="text-danger">{focalNotFound}</p>
                  {focalDatas.map((focalData) => (
                    <tr key={focalData?.contact_id}>
                      <td>{focalData?.contact_designation}</td>
                      <td>{focalData?.contact_phone}</td>
                      <td>{focalData?.contact_email}</td>
                    </tr>
                    ))}
                    {/* <tr>
                      <td>Focal Person PMDU</td>
                      <td>+92-42-9921-2020(1)</td>
                      <td>-</td>
                      </tr>
                      <tr>
                      <td>Focal Person Payroll</td>
                      <td>+92-42-9921-2034</td>
                      <td>-</td>
                      </tr>
                      <tr>
                      <td>Focal Person Pension</td>
                      <td>+92-42-9921-2034</td>
                      <td>-</td>
                      </tr>
                      <tr>
                      <td>Focal Person GPF</td>
                      <td>+92-42-9921-2025</td>
                      <td>-</td>
                    </tr> */}
                  </tbody>
                </table>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Map />
    </>
  );
};
