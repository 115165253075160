import React from "react"
import { useState } from "react"
import axios from "axios"

const DocDownload = () => {

    const userId = localStorage.getItem('userId')

    //console.log('docdownload render')

    // const [docForm , setDocForm] = useState({
    //     dowText: '',
    //     dowFile:'',
    //     dowCategory: ''
    // })

    // const {dowText,dowFile,dowCategory} = docForm

    // const onHandleChangeDow = (e) => {
    //     setDocForm((prevState)=>({
    //         ...prevState, [e.target.name]: e.target.value
    //     }))
    // }

    const [dowText , setDowText] = useState('')
    const [docmFile , setDocmFile] = useState(null)
    const [dowCategory , setDowCategory] = useState('')

    const [dowResult , setDowResult] = useState('')

    const handleFileChange = (e) => {
      setDocmFile(e.target.files[0]);
      };
    
      const handleCategoryChange = (e) => {
        setDowCategory(e.target.value);
      };

    const handleSubmitDoc = async (e) => {
      
      e.preventDefault();

        const formData = new FormData();
        
        formData.append('dowText', dowText);
        formData.append('docmFile', docmFile);
        formData.append('dowCategory', dowCategory);
        formData.append('did', userId);

        // for (const [key, value] of formData.entries()) {
        //   console.log(`${key}:`, value);
        // }
        
        try {

            const response = await axios.post('https://dappo.gov.pk/Dappo/documents_api/docInsert_api.php/', formData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            });
            
            setDowText('');
            setDowCategory('');
            setDocmFile(null);
      
            console.log(response.data); // You can do something with the response data here
            setDowResult(response.data.message)
      
          } catch (error) {
            console.error(error);
          }
    }


  return (
    <>
    <div>
      <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#document">
    Add Documents
  </button>
</div>


<div className="modal" id="document">
  <div className="modal-dialog">
    <div className="modal-content">

     
      <div className="modal-header">
        <h4 className="modal-title">Add Documents</h4>
        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
      </div>

     
      <div className="modal-body">
       <form onSubmit={handleSubmitDoc}>

       <input type='hidden' className="form-control" name='did' id='did' value = {userId} />

       <div className="form-group mb-2">
        <label>Title:</label>
        <input type="text" name="dowText" id="dowText" className="form-control" value={dowText} onChange={(e)=> setDowText(e.target.value)} required />
      </div>


  <div className="form-group mb-2">
        <label >Upload File:</label>
        <input 
        type="file" 
        name="docmFile"
        id="docmFile" 
        className="form-control-file" 
        onChange={handleFileChange} 
        required 
        />
      </div>
  <div className="form-group mb-2">
    <label className="form-label">Document Categories:</label>
    <select className="form-select" value={dowCategory} id="dowCategory" name="dowCategory" 
    onChange={handleCategoryChange}
    required
    >
      <option value=''>--Select--</option>
      <option value='pension'>Pension</option>
      <option value='gpf'>GPF</option>
      <option value='house_requisition'>House Requisition</option>
      <option value='loan_advance'>Loan & Advance</option>
      <option value='hr'>HR</option>
      <option value='other'>Other</option>
    </select>
  </div>
  <button type="submit" className="btn btn-primary">Submit</button>
</form>
      </div>

      
      <div className="modal-footer">
      <p className='bg-info'>{dowResult}</p>
        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
    </div>
    </>
  )
}

export default React.memo(DocDownload)
