import React, { useState, useContext } from "react";
import axios from "axios";
import { userIdContext } from "../App";

const TransferPosting = () => {
  //const {userId} = useContext(userIdContext);

  const userId = localStorage.getItem('userId')

  const [docTitle, setDocTitle] = useState("");
  const [docFile, setDocFile] = useState(null);
  const [docFileResult, setDocFileResult] = useState("");

  const handleDocTitleChange = (event) => {
    setDocTitle(event.target.value);
  };

  const handleDocFileChange = (event) => {
    setDocFile(event.target.files[0]);
  };

  const handleFormSubmitDoc = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    //const userId = formData.get("tid");

    formData.append("docTitle", docTitle);
    formData.append("docFile", docFile);
    formData.append("id", userId);

    try {
      const response = await axios.post(
        "https://dappo.gov.pk/Dappo/transferPosting_api/insertTransferPosting_api.php/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setDocTitle("");
      setDocFile(null);

      //console.log(response.data); // You can do something with the response data here
      setDocFileResult(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary mb-3"
        data-bs-toggle="modal"
        data-bs-target="#transferPosting"
      >
        Add TransferPosting
      </button>

      <div className="modal" id="transferPosting">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Transfer Posting</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={handleFormSubmitDoc}>
                <input
                  type="hidden"
                  className="form-control"
                  name="tid"
                  id="tid"
                  value={userId}
                />

                <div className="form-group mb-2">
                  <label>Title:</label>
                  <input
                    type="text"
                    name="docTitle"
                    id="docTitle"
                    className="form-control"
                    value={docTitle}
                    onChange={handleDocTitleChange}
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <label>Upload File:</label>
                  <input
                    type="file"
                    name="docFile"
                    id="docFile"
                    className="form-control-file"
                    onChange={handleDocFileChange}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>

            <div className="modal-footer">
              <p className="bg-info">{docFileResult}</p>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TransferPosting);
