import React, { useState } from "react";
//import { useState } from "react";
import axios from "axios";

const ContactForm = () => {
  const userId = localStorage.getItem("userId");

  const [contactCategory, setContactCategory] = useState("");
  const [contactDesignationCategory, setContactDesignationCategory] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  const [contactResult, setContactResult] = useState("");


  const handleSubmitContact = async  (e) => {
     e.preventDefault();

     const formData = new FormData();

     formData.append("contactCategory", contactCategory);
     formData.append("contactDesignationCategory", contactDesignationCategory);
     formData.append("phoneNumber", phoneNumber);
     formData.append("contactEmail", contactEmail);
     formData.append("conid", userId);

    //  for (const [key, value] of formData.entries()) {
    //    console.log(`${key}:`, value);
    //  }

    try {
      const response = await axios.post(
        "https://dappo.gov.pk/Dappo/contact_api/contactinsert_api.php/",
        formData
      );

      setContactCategory("");
      setContactDesignationCategory("");
      setPhoneNumber("");
      setContactEmail("");
    

      console.log(response.data); // You can do something with the response data here
      setContactResult(response.data.message);
    } catch (error) {
      console.error(error);
    }
   }

  return (
    <>
      <div>
        <button
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#contactForm"
        >
          Add Contact
        </button>
      </div>

      <div className="modal" id="contactForm">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Contact</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={handleSubmitContact}>
                <input
                  type="hidden"
                  className="form-control"
                  name="conid"
                  id="conid"
                  value={userId}
                />

                <div className="form-group mb-2">
                  <label className="form-label">Contact Categories:</label>
                  <select
                    className="form-select"
                    value={contactCategory}
                    id="contactCategory"
                    name="contactCategory"
                    onChange={(e) => setContactCategory(e.target.value)}
                    required
                  >
                    <option value="">--Select--</option>
                    
                    <option value="management">Management</option>
                    <option value="administration">Administration</option>
                    <option value="focal">Focal</option>
                  </select>
                </div>

                <div className="form-group mb-2">
                  <label className="form-label">Designation:</label>
                  <select
                    className="form-select"
                    value={contactDesignationCategory}
                    id="contactDesignationCategory"
                    name="contactDesignationCategory"
                    onChange={(e) => setContactDesignationCategory(e.target.value)}
                    required
                  >
                 <option value="">--Select--</option>
                    <option value="Director Accounts">Director Accounts</option>
                    <option value="Deputy Director (Admin)">Deputy Director (Admin)</option>
                    <option value="Deputy Director (Agency)">Deputy Director (Agency)</option>

                    <option value="Account Officer (Admin-I)">Account Officer (Admin-I)</option>
                    <option value="Account Officer (Admin-II)">Account Officer (Admin-II)</option>
                    <option value="Account Officer (Payroll)">Account Officer (Payroll)</option>
                    <option value="Account Officer (Pension)">Account Officer (Pension)</option>
                    <option value="Account Officer (GPF)">Account Officer (GPF)</option>
                    
                    <option value="Focal Person PMDU">Focal Person PMDU</option>
                    <option value="Focal Person Payroll">Focal Person Payroll</option>
                    <option value="Focal Person Pension">Focal Person Pension</option>
                    <option value="Focal Person GPF">Focal Person GPF</option>
                    
                  </select>
                </div>

                <div className="form-group mb-2">
                  <label>Phone Number:</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    className="form-control"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group mb-2">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="contactEmail"
                    id="contactEmail"
                    className="form-control"
                    value={contactEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                    
                  />
                </div>

                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>

            <div className="modal-footer">
              <p className="bg-info">{contactResult}</p>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ContactForm);
