import React, { useState } from "react";
import axios from "axios";
// import { userIdContext } from "../App";
// import { useContext } from "react";


function Form() {

  //const {userId} = useContext(userIdContext);
  const userId = localStorage.getItem("userId");
  //const userId = localStorage.getItem("userId");

  const [officerName, setOfficerName] = useState("");
  const [officerDesignation, setOfficerDesignation] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [officerResult, setOfficerResult] = useState("");

  const handleOffierNameChange = (event) => {
    setOfficerName(event.target.value);
  };

  const handleOfficerDesignationChange = (event) => {
    setOfficerDesignation(event.target.value);
  };

  const handleImageChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    //const userId = formData.get("oid");

    formData.append("officerName", officerName);
    formData.append("officerDesignation", officerDesignation);
    formData.append("image", imageFile);
    formData.append("id", userId);

    try {
      const response = await axios.post(
        "https://dappo.gov.pk/Dappo/officer_api/insertOfficer_api.php/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setOfficerName("");
      setOfficerDesignation("");
      setImageFile([null]);

      console.log(response.data); // You can do something with the response data here
      setOfficerResult(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary mb-3"
        data-bs-toggle="modal"
        data-bs-target="#addOfficer"
      >
        Add Officer
      </button>

      <div className="modal" id="addOfficer">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Officer</h4>
              
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              
              <form onSubmit={handleFormSubmit}>
                <input
                  type="hidden"
                  className="form-control"
                  name="oid"
                  id="oid"
                  value={userId}
                />

                <div className="form-group mb-2">
                  <label htmlFor="">Officer Name:</label>
                  <input
                    type="text"
                    name="officerName"
                    id="officerName"
                    className="form-control"
                    value={officerName}
                    onChange={handleOffierNameChange}
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="">Officer Designation :</label>
                  <input
                    type="text"
                    name="officerDesignation"
                    id="officerDesignation"
                    className="form-control"
                    value={officerDesignation}
                    onChange={handleOfficerDesignationChange}
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="image">Upload Image:</label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    className="form-control-file"
                    onChange={handleImageChange}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>

            <div className="modal-footer">
              <p className="bg-info">{officerResult}</p>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(Form);
