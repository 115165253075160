import { Link, useNavigate } from "react-router-dom";

import React, { useContext, useState } from "react";
import "../styles/NavbarCSS.css";
import axios from "axios";

export const NavBar = () => {
  let navigate = useNavigate();
  
  //const [loginUser, setLoginUser] = useState('')
  
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  
  const submitForm = (e) => {
    e.preventDefault();
    const sendData = {
      email: user.email,
      password: user.password,
    };
    //console.log(sendData); https://dappo.gov.pk/Dappo/login_api/login_api.php
    
    axios
    .post(
        `https://dappo.gov.pk/Dappo/login_api/login_api.php`,
        sendData
        )
        .then((result) => {
          //console.log(result.data.userStatus);
          setUser({
            email: "",
            password: "",
          });
          
          if (result.data.userStatus === "active") {
            window.localStorage.setItem("userId", result.data.userId);
            window.localStorage.setItem("email", result.data.userEmail);
            window.localStorage.setItem("userName", result.data.userName);
            
            sessionStorage.setItem("userId", result.data.userId);
            sessionStorage.setItem("email", result.data.userEmail);
            sessionStorage.setItem("userName", result.data.userName);
            //alert(`valid user email and password ${result.data.userName}`);
            
            const userName = localStorage.getItem("userName");
            
            
            
            //  if (userName) {
              //     setLoginUser(userName)
              //  }
              
              navigate("/admin");
            } else {
              alert("Invalid user email and password");
            }
          });
  };

  return (
    <>
      <div className="nav">
        <nav
          className="navbar navbar-expand-lg navbar-dark mb-0"
          id="main-navigation"
          style={{ backgroundColor: "#004D25", width: "100%" }}
        >
          <div className="container-fluid">
            {/*<Link to ="" className="navbar-brand" ><img src={logo2} 
          style={{ height:50, width:150 }}
           alt="" />
          </Link>*/}

            <button
              className="navbar-toggler ms-auto"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav me-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    <strong>Home</strong>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/services" className="nav-link">
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/notification">
                    Notifications
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/gallery">
                    Gallery
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/downloads" className="nav-link">
                    Downloads
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/contact"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    Contact
                  </Link>
                </li>

                {/* {loginUser && (
                <>
                  <li className="nav-item">
                    <Link  to="/admin" className="nav-link">
                      Admin
                    </Link>
                  </li>
                </>
              )}  */}
              </ul>
              <div className="d-flex">
                <ul className="navbar-nav me-auto">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Login
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div className="modal" id="staticBackdrop">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                login
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={submitForm}>
                <div className="mb-3 mt-3">
                  <label for="email">Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    name="email"
                    onChange={handleChange}
                    value={user.email}
                  />
                </div>
                <div className="mb-3">
                  <label for="password">Password:</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Enter password"
                    name="password"
                    onChange={handleChange}
                    value={user.password}
                  />
                </div>
                {/*<div className="form-check mb-3">
      <label className="form-check-label">
        <input className="form-check-input" type="checkbox" name="remember" /> Remember me
      </label>
    </div>*/}
                <button
                  type="submit"
                  name="submit"
                  value="login"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
