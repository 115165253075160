import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Form from "../components/Form";

import CarouselForm from "../components/CarouselForm";
import TransferPosting from "../components/TransferPosting";
import UserForm from "../components/UserNewForm";
import MarqueeModel from "../components/MarqueeModel";
import DocDownload from "../components/DocDownload";
import GalleryForm from "../components/GalleryForm";

import ContactForm from "../components/contactForm";

export const Admin = () => {
  //const value = useContext(userNameContext)

  let navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const signOut = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.removeItem("userName");

    sessionStorage.clear();

    navigate("/");
  };

  
  const [contentManagementNotFound, setContentManagementNotFound] = useState('');
  const [dataGalleryNotFound, setDataGalleryNotFound] = useState('');
  const [dataDocumentNotFound, setDataDocumentNotFound] = useState('');
  const [usersNotFound, setUsersNotFound] = useState('');
  const [officersNotFound, setOfficersNotFound] = useState('');
  const [carouselsNotFound, setCarouselsNotFound] = useState(''); 
  const [marqueesNotFound, setMarqueesNotFound] = useState('');



  const [updateContactCategory, setUpdateContactCategory] = useState("");
  const [updateContactDesignationCategory,setUpdateContactDesignationCategory] = useState("");
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState("");
  const [updateContactEmail, setUpdateContactEmail] = useState("");
  const [updateContactResult, setUpdateContactResult] = useState("");

  const [galleryFetchs, setGalleryFetchs] = useState([]);

  const [dataDocuments, setDataDocuments] = useState([]);
  const [documentsFetchs, setDocumentsFetchs] = useState([]);
  const [docTitleUpdate, setDocTitleUpdate] = useState("");
  const [docFileUpdate, setDocFileUpdate] = useState("");
  const [docCategoryUpdate, setDocCategoryUpdate] = useState("");

  const [docUpdateResult, setDocUpdateResult] = useState("");

  const handleDocTitleUpdateChange = (event) => {
    setDocTitleUpdate(event.target.value);
  };

  const handleChangeDocUpdateFile = (event) => {
    setDocFileUpdate(event.target.files[0]);
  };

  const handleCategoryChange = (event) => {
    setDocCategoryUpdate(event.target.value);
  };

  const updateDocument = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const updateIdDocument = formData.get("updateIdDocument");
    formData.append("updateIdDocument", updateIdDocument);
    formData.append("docTitleUpdate", docTitleUpdate);
    formData.append("docFileUpdate", docFileUpdate);
    formData.append("docCategoryUpdate", docCategoryUpdate);

    //console.log(formData) https://dappo.gov.pk/

    try {
      const response = await axios.post(
        "https://dappo.gov.pk/Dappo/documents_api/docUpdate_api.php/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setDocTitleUpdate("");
      setDocFileUpdate("");
      setDocCategoryUpdate("");

      setDocUpdateResult(response.data.message); // You can do something with the response data here
      //setTransferPostingUpdateResult(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitContactUpdate = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const updateConid = formData.get("updateConid");
    formData.append("updateContactCategory", updateContactCategory);
    formData.append(
      "updateContactDesignationCategory",
      updateContactDesignationCategory
    );
    formData.append("updatePhoneNumber", updatePhoneNumber);
    formData.append("updateContactEmail", updateContactEmail);

    //console.log(formData) https://dappo.gov.pk/

    try {
      const response = await axios.post(
        "https://dappo.gov.pk/Dappo/contact_api/contactupdate_api.php",
        formData
      );

      setUpdateContactCategory("");
      setUpdateContactDesignationCategory("");
      setUpdatePhoneNumber("");
      setUpdateContactEmail("");

      setUpdateContactResult(response.data.message); // You can do something with the response data here
      //setTransferPostingUpdateResult(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const [transferDataNotFound, setTransferDataNotFound] = useState('');
  const [transferPostings, setTransferPostings] = useState([]);
  const [transferPostingFetchs, setTransferPostingFetchs] = useState([]);
  const [titleUpdate, setTitleUpdate] = useState("");
  const [transferPostingFileUpdate, setTransferPostingFileUpdate] =
    useState("");
  const [transferPostingUpdateResult, setTransferPostingUpdateResult] =
    useState("");

  const [carousels, setCarousels] = useState([]);
  const [carouselFetchs, setCarouselFetchs] = useState([]);
  const [carouselNameUpdate, setCarouselNameUpdate] = useState("");
  const [carouselLinkUpdate, setCarouselLinkUpdate] = useState("");
  const [carouselImageFileUpdate, setCarouselImageFileUpdate] = useState(null);
  const [carouselUpdateResult, setCarouselUpdateResult] = useState("");

  const handleCarouselNameUpdateChange = (event) => {
    setCarouselNameUpdate(event.target.value);
  };

  const handleCarouselLinkUpdateChange = (event) => {
    setCarouselLinkUpdate(event.target.value);
  };

  const handleChangeUpdateImageCarousel = (event) => {
    setCarouselImageFileUpdate(event.target.files[0]);
  };

  const handleTitleUpdateChange = (event) => {
    setTitleUpdate(event.target.value);
  };

  const handleChangeUpdateFile = (event) => {
    setTransferPostingFileUpdate(event.target.files[0]);
  };
  const fetchTransferPostingData = async (id) => {
    const sendData = {
      updateId: id,
    };
    //console.log(sendData);

    const res = await axios.post(
      `https://dappo.gov.pk/Dappo/transferPosting_api/transferPostingSingle_api.php/`,
      sendData
    );

    //console.log(res.data.result);
    //console.log(res.data);
    setTransferPostingFetchs(res.data);
  };

  const transferPostingFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const updateIdTransferPosting = formData.get("updateIdTransferPosting");
    formData.append("updateIdTransferPosting", updateIdTransferPosting);
    formData.append("titleUpdate", titleUpdate);
    formData.append("transferPostingFileUpdate", transferPostingFileUpdate);

    //console.log(formData)

    try {
      const response = await axios.post(
        "https://dappo.gov.pk/Dappo/transferPosting_api/updateTransferPosting_api.php/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setTitleUpdate("");

      setTransferPostingFileUpdate([]);

      //console.log(response.data); // You can do something with the response data here
      setTransferPostingUpdateResult(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const transferPostingdelete = async (id) => {
    //alert(marqueeId);
    const confirmed = window.confirm(
      "Are you sure you want to delete this transferPosting order?"
    );
    if (confirmed) {
      //alert(`maruqee title deleted ${marqueeId}`)

      const sendData = {
        id: id,
      };

      //console.log(sendData);

      await axios
        .post(
          `https://dappo.gov.pk/Dappo/transferPosting_api/deleteTransferPosting_api.php/`,
          sendData
        )
        .then((result) => {
          //console.log(result.data);
          alert(result.data.message);
        });
    }
  };

  const userdelete = async (id) => {
    //alert(marqueeId);
    const confirmed = window.confirm(
      "Are you sure you want to delete this user account?"
    );
    if (confirmed) {
      //alert(`maruqee title deleted ${marqueeId}`)

      const sendData = {
        id: id,
      };

      //console.log(sendData);

      await axios
        .post(
          `https://dappo.gov.pk/Dappo/users_api/userDelete_api.php`,
          sendData
        )
        .then((result) => {
          //console.log(result.data);
          alert(result.data.message);
        });
    }
  };

  const [officers, setOfficers] = useState([]);
  const [marquees, setMarquees] = useState([]);

  const [marqFetchs, setMarqFetchs] = useState([]);
  const [marqFormData, setMarqFormData] = useState({
    title: "",
  });

  const [officerFetchs, setOfficerFetchs] = useState([]);
  const [userFetchs, setUserFetchs] = useState([]);

  const [galleryNameUpdate, setGalleryNameUpdate] = useState("");
  const [galleryCategoryUpdate, setGalleryCategoryUpdate] = useState("");
  const [galleryLinkUpdate, setGalleryLinkUpdate] = useState("");
  const [galleryImageUpdate, setGalleryImageUpdate] = useState("");

  const [galleryResult, setGalleryResult] = useState("");

  const handleGalleryImageChange = (event) => {
    setGalleryImageUpdate(event.target.files[0]);
  };

  const handleGalleryNameChange = (event) => {
    setGalleryNameUpdate(event.target.value);
  };

  const galleryFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const updateId = formData.get("updateGalleryId");
    formData.append("updateId", updateId);
    formData.append("galleryNameUpdate", galleryNameUpdate);
    formData.append("galleryCategoryUpdate", galleryCategoryUpdate);
    formData.append("galleryLinkUpdate", galleryLinkUpdate);
    formData.append("updateImageGallery", galleryImageUpdate);

    //console.log(formData)

    try {
      const response = await axios.post(
        "https://dappo.gov.pk/Dappo/gallery_api/updateGallery_api.php/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setGalleryNameUpdate("");
      setGalleryImageUpdate([null]);

      console.log(response.data); // You can do something with the response data here
      setGalleryResult(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const [officerNameUpdate, setOfficerNameUpdate] = useState("");
  const [officerDesignationUpdate, setOfficerDesignationUpdate] = useState("");

  const [userEmailUpdate, setUserEmailUpdate] = useState("");
  const [userPasswordUpdate, setUserPasswordUpdate] = useState("");
  const [userNameUpdate, setUserNameUpdate] = useState("");

  const [updateOfficerResult, setUpdateOfficerResult] = useState("");

  const handleUserEmailUpdateChange = (event) => {
    setUserEmailUpdate(event.target.value);
  };
  const handleUserPasswordUpdateChange = (event) => {
    setUserPasswordUpdate(event.target.value);
  };
  const handleUserNameUpdateChange = (event) => {
    setUserNameUpdate(event.target.value);
  };

  const handleOffierNameUpdateChange = (event) => {
    setOfficerNameUpdate(event.target.value);
  };

  const handleOfficerDesignationUpdateChange = (event) => {
    setOfficerDesignationUpdate(event.target.value);
  };
  const [imageFileUpdate, setImageFileUpdate] = useState(null);

  const handleChangeUpdateImage = (event) => {
    setImageFileUpdate(event.target.files[0]);
  };

  const [marqResult, setMarqResult] = useState("");

  const [auth, setAuth] = useState("");
  const [authEmail, setAuthEmail] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const email = localStorage.getItem("email");

    const sessionUserId = sessionStorage.getItem("userId");
    const sessionUserName = sessionStorage.getItem("userName");
    const sessionEmail = sessionStorage.getItem("email");

    if (!sessionUserId && !sessionUserName && !sessionEmail) {
      navigate("/");
    } else {
      getDataCarousel();
      getData();
      getDataOfficer();
      getDataMarquee();
      getDataTransferPosting();
      getDataDocument();
      getDataGallery();
      getContactManagement();
    }

    setAuth(userName);
    setAuthEmail(email);
  }, [navigate]);

  const [galleryDatas, setGalleryData] = useState([]);

  const [contactsMang, setContactMang] = useState([]);

  const getContactManagement = async () => {
    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/contact_api/contactAll_api.php`
    );

    if (res.data.status === false) {
      setContentManagementNotFound(res.data.message)
    } else {
      setContactMang(res.data);
    }
    //console.log(res.data)
    
  };

  const getDataGallery = async () => {
    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/gallery_api/galleryAll_api.php`
    );
    //console.log(res.data)
    if (res.data.status === false) {
      setDataGalleryNotFound(res.data.message)
    } else {
      setGalleryData(res.data);
    }
    //setGalleryData(res.data);
  };

  const getDataDocument = async () => {
    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/documents_api/docAll_api.php`
    );

    if (res.data.status === false) {
      setDataDocumentNotFound(res.data.message)
    } else {
      setDataDocuments(res.data);
    }

    //setDataDocuments(res.data);
  };

  const getDataTransferPosting = async () => {
    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/transferPosting_api/transferPostingAll_api.php`
    );

    //console.log(res.data.message);
    //console.log(res.data.message);
    //setTransferPostings(res.data);
    //setTransferPostings(res.data.message);
    if (res.data.status === false) {
      setTransferDataNotFound(res.data.message)
    } else {
      setTransferPostings(res.data);
    }

  };

  const getData = async () => {
    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/users_api/userfetchAll_api.php`
    );

    if (res.data.status === false) {
      setUsersNotFound(res.data.message)
    } else {
      setUsers(res.data);
    }

    //console.log(res.data.result);
    //console.log(res.data);
    //setUsers(res.data);
  };

  const getDataOfficer = async () => {
    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/officer_api/officerAll_api.php`
    );

    if (res.data.status === false) {
      setOfficersNotFound(res.data.message)
    } else {
      setOfficers(res.data);
    }
    //console.log(res.data.result);
    //console.log(res.data);
    //setOfficers(res.data);
  };

  // carousel session

  const getDataCarousel = async () => {
    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/carousel_api/carouselAll_api.php`
    );
    
    if (res.data.status === false) {
      setCarouselsNotFound(res.data.message)
    } else {
      setCarousels(res.data);
    }
    //console.log(res.data.result);
    //console.log(res.data);
    //setCarousels(res.data);
  };

  const gallerydelete = async (id) => {
    //alert(marqueeId);
    const confirmed = window.confirm(
      "Are you sure you want to delete this Gallery image?"
    );
    if (confirmed) {
      //alert(`maruqee title deleted ${marqueeId}`)

      const sendData = {
        id: id,
      };

      //console.log(sendData)

      //console.log(sendData); https://dappo.gov.pk/Dappo/gallery_api/deleteGallery_api.php/

      await axios
        .post(
          `https://dappo.gov.pk/Dappo/gallery_api/deleteGallery_api.php/`,
          sendData
        )
        .then((result) => {
          //console.log(result.data);
          alert(result.data.message);
        });
    }
  };

  const carouseldelete = async (id) => {
    //alert(marqueeId);
    const confirmed = window.confirm(
      "Are you sure you want to delete this carousel image?"
    );
    if (confirmed) {
      //alert(`maruqee title deleted ${marqueeId}`)

      const sendData = {
        id: id,
      };

      //console.log(sendData);

      await axios
        .post(
          `https://dappo.gov.pk/Dappo/carousel_api/deleteCarousel_api.php/`,
          sendData
        )
        .then((result) => {
          //console.log(result.data);
          alert(result.data.message);
        });
    }
  };

  const contactDelete = async (id) => {
    //alert(marqueeId);
    const confirmed = window.confirm(
      "Are you sure you want to delete this carousel image?"
    );
    if (confirmed) {
      //alert(`maruqee title deleted ${marqueeId}`)

      const sendData = {
        id: id,
      };

      //console.log(sendData);

      await axios
        .post(
          `https://dappo.gov.pk/Dappo/contact_api/contactDelete_api.php/`,
          sendData
        )
        .then((result) => {
          //console.log(result.data);
          alert(result.data.message);
        });
    }
  };

  const fetchCarouselData = async (id) => {
    const sendData = {
      updateId: id,
    };
    //console.log(sendData);

    const res = await axios.post(
      `https://dappo.gov.pk/Dappo/carousel_api/carouselSingle_api.php/`,
      sendData
    );

    //console.log(res.data.result);
    //console.log(res.data);
    setCarouselFetchs(res.data);
  };

  const carouselFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const updateIdcarousel = formData.get("updateIdcarousel");
    formData.append("updateIdcarousel", updateIdcarousel);
    formData.append("carouselNameUpdate", carouselNameUpdate);
    formData.append("carouselImageFileUpdate", carouselImageFileUpdate);
    formData.append("carouselLinkUpdate", carouselLinkUpdate);

    //console.log(formData)

    try {
      const response = await axios.post(
        "https://dappo.gov.pk/Dappo/carousel_api/updateCarousel_api.php/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setCarouselNameUpdate("");

      setCarouselLinkUpdate("");

      setCarouselImageFileUpdate(null);

      //console.log(response.data); // You can do something with the response data here
      setCarouselUpdateResult(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchOfficerData = async (id) => {
    const sendData = {
      updateId: id,
    };
    //console.log(sendData);

    const res = await axios.post(
      `https://dappo.gov.pk/Dappo/officer_api/officerSingle_api.php/`,
      sendData
    );

    //console.log(res.data.result);
    //console.log(res.data);
    setOfficerFetchs(res.data);
  };

  const [contactFetchs, setContactFetchs] = useState([]);

  const fetchContactData = async (id) => {
    const sendData = {
      updateId: id,
    };
    //console.log(sendData);

    const res = await axios.post(
      `https://dappo.gov.pk/Dappo/contact_api/contactfetchSingle_api.php/`,
      sendData
    );

    //console.log(res.data.result);
    //console.log(res.data);
    setContactFetchs(res.data);
  };

  const fetchUserData = async (id) => {
    const sendData = {
      updateId: id,
    };
    //console.log(sendData);

    const res = await axios.post(
      `https://dappo.gov.pk/Dappo/users_api/userfetchSingle_api.php/`,
      sendData
    );

    //console.log(res.data.result);
    //console.log(res.data);
    setUserFetchs(res.data);
  };

  const officerFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const updateId = formData.get("updateId");
    formData.append("updateId", updateId);
    formData.append("officerNameUpdate", officerNameUpdate);
    formData.append("officerDesignationUpdate", officerDesignationUpdate);
    formData.append("imageFileUpdate", imageFileUpdate);

    //console.log(formData)

    try {
      const response = await axios.post(
        "https://dappo.gov.pk/Dappo/officer_api/updateOfficer_api.php/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setOfficerNameUpdate("");
      setOfficerDesignationUpdate("");
      setImageFileUpdate([null]);

      setUpdateOfficerResult(response.data.message); // You can do something with the response data here
    } catch (error) {
      console.error(error);
    }
  };

  const [userUpdateResult, setUserUpdateResult] = useState("");

  const userUpdateFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const updateId = formData.get("userupdateId");
    formData.append("userupdateId", updateId);
    formData.append("userEmailUpdate", userEmailUpdate);
    formData.append("userPasswordUpdate", userPasswordUpdate);
    formData.append("userNameUpdate", userNameUpdate);

    //console.log(formData.updateId)

    try {
      const response = await axios.post(
        "https://dappo.gov.pk/Dappo/users_api/userupdate_api.php/",
        formData
      );
      //console.log(response.data.messag)

      setUserEmailUpdate("");
      setUserPasswordUpdate("");
      setUserNameUpdate("");

      setUserUpdateResult(response.data.message); // You can do something with the response data here
    } catch (error) {
      console.error(error);
    }
  };

  const getDataMarquee = async () => {
    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/marquee_api/marqueeAll_api.php`
    );

    if (res.data.status === false) {
      setMarqueesNotFound(res.data.message)
    } else {
      setMarquees(res.data);
    }
    

    //console.log(res.data.result);
    //console.log(res.data);
    //setMarquees(res.data);
  };

  const fetchMarqueeData = async (id) => {
    const sendData = {
      id: id,
    };
    //console.log(id);

    const res = await axios.post(
      `https://dappo.gov.pk/Dappo/marquee_api/marqueeSingle_api.php/`,
      sendData
    );

    //console.log(res.data.result);
    //console.log(res.data);
    setMarqFetchs(res.data);
  };

  const handleChange = (e) => {
    setMarqFormData({ ...marqFormData, [e.target.name]: e.target.value });
  };

  const marqeeFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const hiddenValue = formData.get("id");
    //alert("ok")
    const sendData = {
      id: hiddenValue,
      title: marqFormData.title,
    };
    //console.log(sendData)

    axios
      .post(
        `https://dappo.gov.pk/Dappo/marquee_api/marqueeUpdate_api.php/`,
        sendData
      )
      .then((result) => {
        //console.log(result.data);
        setMarqResult(result.data.message);
      });
  };

  const marqueedelete = async (id) => {
    //alert(marqueeId);
    const confirmed = window.confirm(
      "Are you sure you want to delete this Marquee Title?"
    );
    if (confirmed) {
      //alert(`maruqee title deleted ${marqueeId}`)

      const sendData = {
        id: id,
      };

      //console.log(sendData);

      await axios
        .post(
          `https://dappo.gov.pk/Dappo/marquee_api/marqueeDelete_api.php/`,
          sendData
        )
        .then((result) => {
          //console.log(result.data);
          alert(result.data.message);
        });
    }
  };
  // curd function Officer

  const officerdelete = async (id) => {
    //alert(marqueeId);
    const confirmed = window.confirm(
      "Are you sure... you want to delete this Officer Record?"
    );
    if (confirmed) {
      //alert(`maruqee title deleted ${marqueeId}`)

      const sendData = {
        id: id,
      };

      console.log(sendData);

      await axios
        .post(
          `https://dappo.gov.pk/Dappo/officer_api/deleteOfficer_api.php/`,
          sendData
        )
        .then((result) => {
          console.log(result.data);
          alert(result.data.message);
        });
    }
  };

  const dataDocumentsdelete = async (id) => {
    //alert(marqueeId);
    const confirmed = window.confirm(
      "Are you sure you want to delete this Marquee Title?"
    );
    if (confirmed) {
      //alert(`maruqee title deleted ${marqueeId}`)

      const sendData = {
        id: id,
      };

      //console.log(sendData);

      await axios
        .post(
          `https://dappo.gov.pk/Dappo/documents_api/docDelete_api.php/`,
          sendData
        )
        .then((result) => {
          //console.log(result.data);
          alert(result.data.message);
        });
    }
  };

  const fetchDataDocuments = async (id) => {
    const sendData = {
      id: id,
    };
    //console.log(id);

    const res = await axios.post(
      `https://dappo.gov.pk/Dappo/documents_api/documentSingle_api.php/`,
      sendData
    );

    //console.log(res.data.result);
    //console.log(res.data);
    setDocumentsFetchs(res.data);
  };

  const fetchGalleryDatas = async (id) => {
    const sendData = {
      id: id,
    };
    //console.log(id);

    const res = await axios.post(
      `https://dappo.gov.pk/Dappo/gallery_api/gallerySingle_api.php/`,
      sendData
    );

    //console.log(res.data.result);
    //console.log(res.data);
    setGalleryFetchs(res.data);
  };

  // const pensionObject = {
  //   toggleModel: '#pension',
  //   toggleButton: 'Add Pension Document',
  //   modelId: 'pension',
  //   modelTitle: 'Pension Document',
  //   inputNameId: 'pensionTitle',
  //   inputFileNameId: ''
  // }

  return (
    <>
      <div className="container">
        <h1 className="text-danger">Welcome to Admin: {auth}</h1>
        <button className="btn btn-danger" onClick={() => signOut()}>
          Admin LogOut
        </button>
        <p className="text-danger">Email: {authEmail}</p>
        {/* <p className="text-danger">context value throught hook app.js: {value}</p> */}

        <div className="">
          <h1>Transfer Posting Letter</h1>
          <TransferPosting />
          <div class="table-responsive">
          <table className="table">
            <thead>
              <tr className="table-success">
                <th>#</th>
                <th>File Name</th>
                <th>Title</th>
                <th>Date</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead>
            <tbody>

              <p className="mx-auto text-danger">{transferDataNotFound}</p>


              {transferPostings?.map((transferPosting) => (
                <tr
                className="table-danger"
                key={transferPosting?.transferPosting_id}
                >
                  <td>{transferPosting?.transferPosting_id}</td>
                  <td>
                    <a
                      href={`https://dappo.gov.pk/Dappo/upload/${transferPosting?.transferPosting_fileName}`}
                    >
                      {transferPosting?.transferPosting_fileName}
                    </a>
                  </td>
                  <td>{transferPosting?.transferPosting_title}</td>
                  <td>{transferPosting?.transferPosting_date}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      data-bs-toggle="modal"
                      data-bs-target="#updateTransferPosting"
                      onClick={() =>
                        fetchTransferPostingData(
                          transferPosting?.transferPosting_id
                          )
                      }
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() =>
                        transferPostingdelete(
                          transferPosting?.transferPosting_id
                          )
                        }
                        >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
              </div>

          <h1>Carousel Images</h1>
          <CarouselForm />
          <div class="table-responsive">
          <table className="table">
            <thead>
              <tr className="table-success">
                <th>#</th>
                <th>Carousel Name</th>
                <th>Carousel Image</th>
                <th>Carousel Link</th>
                <th>Carousel Status</th>
                <th>Carousel Date</th>

                <th colSpan="2">Action</th>
              </tr>
            </thead>
            <tbody>
            <p className="text-danger">{carouselsNotFound}</p>

              {carousels.map((carousel) => (
                <tr className="table-danger" key={carousel?.carousel_id}>
                  <td>{carousel?.carousel_id}</td>
                  <td>{carousel?.carousel_name}</td>
                  <td>
                    <img
                      src={`https://dappo.gov.pk/Dappo/upload/${carousel?.carousel_image}`}
                      className=""
                      style={{ height: "10rem", width: "5rem" }}
                      alt=""
                      />
                    {carousel?.carousel_image}
                  </td>
                  <td>{carousel?.carousel_link}</td>
                  <td>{carousel?.carousel_status}</td>
                  <td>{carousel?.date}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      data-bs-toggle="modal"
                      data-bs-target="#updateCarousel"
                      onClick={() => fetchCarouselData(carousel?.carousel_id)}
                      >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => carouseldelete(carousel?.carousel_id)}
                      >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
              </div>

          <h1>Users</h1>

          <UserForm />
          <div class="table-responsive">

          <table className="table table-responsive">
            <thead>
              <tr className="table-success">
                <th>#</th>
                <th>user email</th>
                <th>user password</th>
                <th>user name</th>
                <th>user date</th>
                <th>user status</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead>
            <tbody>
            <p className="mx-auto text-danger">{usersNotFound} </p>
              {users.map((user) => (
                <tr className="table-danger" key={user?.userId}>
                  <td>{user?.userId}</td>
                  <td>{user?.userEmail}</td>
                  <td>{user?.userPassword}</td>
                  <td>{user?.userName}</td>
                  <td>{user?.userDate}</td>
                  <td>{user?.userType}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      data-bs-toggle="modal"
                      data-bs-target="#updateUser"
                      onClick={() => fetchUserData(user?.userId)}
                      >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => userdelete(user?.userId)}
                      >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
              </div>

          <h1>Officers</h1>
          <Form />
          <div class="table-responsive">

          <table className="table">
            <thead>
              <tr className="table-success">
                <th>Officer Id</th>
                <th>Officer Name</th>
                <th>Officer Designation</th>
                <th>Officer Image</th>

                <th>Date</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead>
            <tbody>
            <p className="mx-auto text-danger">{officersNotFound} </p>
              {officers.map((officer) => (
                <tr className="table-danger" key={officer?.officer_id}>
                  <td>{officer?.officer_id}</td>
                  <td>{officer?.officer_name}</td>
                  <td>{officer?.officer_designation}</td>
                  <td>
                    <img
                      src={`https://dappo.gov.pk/Dappo/upload/${officer?.officer_image}`}
                      className=""
                      style={{ height: "10rem", width: "5rem" }}
                      alt=""
                      />
                    {/* //<Imagesofficer pathImage={officer?.officer_image}/> */}
                    {officer?.officer_image}
                  </td>
                  <td>{officer?.enter_date}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      data-bs-toggle="modal"
                      data-bs-target="#updateOfficer"
                      onClick={() => fetchOfficerData(officer?.officer_id)}
                      >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => officerdelete(officer?.officer_id)}
                      >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
              </div>

          <h1>Marquee</h1>

          <MarqueeModel />
          <div class="table-responsive">

          <table className="table">
            <thead className="mx-auto">
              <tr className="table-success">
                <th>Marquee Id</th>
                <th>Marquee Title</th>
                <th>Marquee Status</th>
                <th>Marquee Date</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead>
            <tbody>
            <p className="mx-auto text-danger">{marqueesNotFound} </p>
              {marquees.map((marquee) => (
                <tr className="table-danger" key={marquee?.marquee_id}>
                  <td>{marquee?.marquee_id}</td>
                  <td>{marquee?.marquee_title}</td>
                  <td>{marquee?.marquee_status}</td>
                  <td>{marquee?.marquee_date}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      data-bs-toggle="modal"
                      data-bs-target="#updateMarq"
                      onClick={() => fetchMarqueeData(marquee?.marquee_id)}
                      >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => marqueedelete(marquee?.marquee_id)}
                    >
                      Delete
                    </button>
                  </td>
                  {/* <td><Link to={`/admin/marqupdate/${marquee.marquee_id}`} class="btn btn-success">Update</Link></td>
                <td><Link to={marquee.marquee_id} class="btn btn-danger">Delete</Link></td>  */}
                </tr>
              ))}
            </tbody>
          </table>
              </div>

          <h1>Document</h1>

          <DocDownload />
          <br />
          <div class="table-responsive">

          <table className="table">
            <thead>
              <tr className="table-success">
                <th>#</th>
                <th>Documents Title</th>
                <th>File Name</th>
                <th>File Category</th>
                <th>Date</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead>
            <tbody>
            <p className="mx-auto text-danger">{dataDocumentNotFound}</p>
              {dataDocuments?.map((dataDocument) => (
                <tr className="table-danger" key={dataDocument?.d_id}>
                  <td>{dataDocument?.d_id}</td>
                  <td>{dataDocument?.d_title}</td>
                  <td>
                    <a
                      href={`https://dappo.gov.pk/Dappo/upload/${dataDocument?.d_fileName}`}
                      >
                      {dataDocument?.d_fileName}{" "}
                    </a>
                  </td>
                  <td>{dataDocument?.d_category}</td>
                  <td>{dataDocument?.d_date}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      data-bs-toggle="modal"
                      data-bs-target="#updateDocuments"
                      onClick={() => fetchDataDocuments(dataDocument?.d_id)}
                      >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => dataDocumentsdelete(dataDocument?.d_id)}
                      >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
              </div>

          <h1>Gallery Images</h1>

          <GalleryForm />
          <br />
          <div class="table-responsive">

          <table className="table">
            <thead>
              <tr className="table-success">
                <th>#</th>
                <th>Title or Content</th>
                <th>Category</th>
                <th>File Name</th>
                <th>Date</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead>
            <tbody>
            <p className="mx-auto text-danger">{dataGalleryNotFound} </p>
              {galleryDatas?.map((galleryData) => (
                <tr className="table-danger" key={galleryData?.g_id}>
                  <td>{galleryData?.g_id}</td>
                  <td>{galleryData?.g_category}</td>
                  <td>{galleryData?.g_content}</td>
                  <td>
                    <img
                      src={`https://dappo.gov.pk/Dappo/upload/${galleryData?.g_fileName}`}
                      className=""
                      style={{ height: "10rem", width: "5rem" }}
                      alt=""
                    />
                  </td>
                  <td>{galleryData?.g_timeStamp}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      data-bs-toggle="modal"
                      data-bs-target="#updateGallery"
                      onClick={() => fetchGalleryDatas(galleryData?.g_id)}
                      >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => gallerydelete(galleryData?.g_id)}
                      >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
              </div>

          <h1>Contact</h1>
          <ContactForm />
          <div class="table-responsive">

          <table className="table mt-4">
            <thead>
              <tr className="table-success">
                <th>contact Id</th>
                <th>Designation</th>
                <th>Phone</th>
                <th>Email</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead>
            <tbody>
            <p className="mx-auto text-danger">{contentManagementNotFound}</p>
              {contactsMang.map((contactMng) => (
                <tr className="table-danger" key={contactMng?.contact_id}>
                  <td>{contactMng?.contact_id}</td>
                  <td>{contactMng?.contact_designation}</td>
                  <td>{contactMng?.contact_phone}</td>
                  <td>{contactMng?.contact_email}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      data-bs-toggle="modal"
                      data-bs-target="#updateContact"
                      onClick={() => fetchContactData(contactMng?.contact_id)}
                      >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => contactDelete(contactMng?.contact_id)}
                      >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
              </div>

          {/* marq update model */}

          <div className="modal" id="updateMarq">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Marquee Title</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  {marqFetchs.map((marqFetch) => (
                    <div key={marqFetch?.marquee_id}>
                      <b>Select Marquee Title:</b>
                      <p>{marqFetch?.marquee_title}</p>
                      <form onSubmit={marqeeFormSubmit}>
                        <div className="mb-3 mt-3">
                          <label htmlFor="name" className="form-label">
                            <b>Update Marquee Title:</b>
                          </label>
                          <input
                            type="hidden"
                            name="id"
                            id="id"
                            value={marqFetch?.marquee_id}
                          />

                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            onChange={handleChange}
                            value={marqFormData?.title}
                            name="title"
                            required
                          />
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </div>
                  ))}
                </div>
                <div className="modal-footer">
                  <p className="bg-info">{marqResult}</p>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* officer update model */}

          <div className="modal" id="updateOfficer">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Officer Record</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  {officerFetchs.map((officerFetch) => (
                    <div key={officerFetch?.officer_id}>
                      <b>Select Officer Record:</b>
                      <p>{officerFetch?.officer_name}</p>
                      <p>{officerFetch?.officer_designation}</p>

                      <form onSubmit={officerFormSubmit}>
                        <div className="mb-3 mt-3">
                          <input
                            type="hidden"
                            name="updateId"
                            id="updateId"
                            value={officerFetch?.officer_id}
                          />
                          <label htmlFor="officerName" className="form-label">
                            <b>Officer Name:</b>
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="officerNameUpdate"
                            onChange={handleOffierNameUpdateChange}
                            value={officerNameUpdate}
                            name="officerNameUpdate"
                            required
                          />
                          <label
                            htmlFor="officerDesignation"
                            className="form-label"
                          >
                            <b>Officer Designation:</b>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="officerDesignationUpdate"
                            onChange={handleOfficerDesignationUpdateChange}
                            value={officerDesignationUpdate}
                            name="officerDesignationUpdate"
                            required
                          />

                          <label htmlFor="image">Upload Image:</label>
                          <input
                            type="file"
                            name="imageFileUpdate"
                            id="imageFileUpdate"
                            className="form-control-file"
                            onChange={handleChangeUpdateImage}
                            required
                          />
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </div>
                  ))}
                </div>
                <div className="modal-footer">
                  <p className="text-success">{updateOfficerResult}</p>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* carousel update model */}
          <div className="modal" id="updateCarousel">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Carousel Record</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  {carouselFetchs.map((carouselFetch) => (
                    <div key={carouselFetch?.carousel_id}>
                      <b>Select Officer Record:</b>
                      <p className="bg-success">
                        {carouselFetch?.carousel_name}
                      </p>
                      <p className="bg-success">
                        {carouselFetch?.carousel_link}
                      </p>

                      <form onSubmit={carouselFormSubmit}>
                        <div className="mb-3 mt-3">
                          <input
                            type="hidden"
                            name="updateIdcarousel"
                            id="updateIdcarousel"
                            value={carouselFetch?.carousel_id}
                          />
                          <label htmlFor="carouselName" className="form-label">
                            <b>carousel Name:</b>
                          </label>

                          <input
                            type="text"
                            className="form-control mb-2"
                            id="carouselNameUpdate"
                            onChange={handleCarouselNameUpdateChange}
                            value={carouselNameUpdate}
                            name="carouselNameUpdate"
                            required
                          />

                          <label htmlFor="carouselLink" className="form-label">
                            <b>carousel Link:"https://abc.gov.pk"</b>
                          </label>

                          <input
                            type="text"
                            className="form-control mb-2"
                            id="carouselLinkUpdate"
                            onChange={handleCarouselLinkUpdateChange}
                            value={carouselLinkUpdate}
                            name="carouselLinkUpdate"
                          />

                          <label htmlFor="carouselimage">Upload Image:</label>
                          <input
                            type="file"
                            name="carouselImageFileUpdate"
                            id="carouselImageFileUpdate"
                            className="form-control-file"
                            onChange={handleChangeUpdateImageCarousel}
                            required
                          />
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </div>
                  ))}
                </div>
                <div className="modal-footer">
                  <p className="bg-info">{carouselUpdateResult}</p>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Transferposting update model */}
          <div className="modal" id="updateTransferPosting">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">
                    Update Transfer Posting Record
                  </h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  {transferPostingFetchs.map((transferPostingFetch) => (
                    <div key={transferPostingFetch?.transferPosting_id}>
                      <b>Select Transfer Posting Order Record:</b>
                      <p className="bg-success">
                        {transferPostingFetch?.transferPosting_title}
                      </p>

                      <form onSubmit={transferPostingFormSubmit}>
                        <div className="mb-3 mt-3">
                          <input
                            type="hidden"
                            name="updateIdTransferPosting"
                            id="updateIdTransferPosting"
                            value={transferPostingFetch?.transferPosting_id}
                          />
                          <label
                            htmlFor="transferPostingTitle"
                            className="form-label"
                          >
                            <b>Title:</b>
                          </label>

                          <input
                            type="text"
                            className="form-control mb-2"
                            id="titleUpdate"
                            onChange={handleTitleUpdateChange}
                            value={titleUpdate}
                            name="titleUpdate"
                            
                          />
                          <label htmlFor="transferPostingFileUpdate">
                            Upload Image:
                          </label>
                          <input
                            type="file"
                            name="transferPostingFileUpdate"
                            id="transferPostingFileUpdate"
                            className="form-control-file"
                            onChange={handleChangeUpdateFile}
                            
                          />
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </div>
                  ))}
                </div>
                <div className="modal-footer">
                  <p className="bg-info">{transferPostingUpdateResult}</p>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* User update model */}
          <div className="modal" id="updateUser">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update User Record</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  {userFetchs.map((userFetch) => (
                    <div key={userFetch?.userId}>
                      <b>Select User Record:</b>
                      <p className="bg-success">{userFetch?.userEmail}</p>
                      <p className="bg-success">{userFetch?.userPassword}</p>
                      <p className="bg-success">{userFetch?.userName}</p>

                      <form onSubmit={userUpdateFormSubmit}>
                        <div className="mb-3 mt-3">
                          <input
                            type="hidden"
                            name="userupdateId"
                            id="userupdateId"
                            value={userFetch?.userId}
                          />
                          <label
                            htmlFor="userEmailUpdate"
                            className="form-label"
                          >
                            <b>Update Email:</b>
                          </label>

                          <input
                            type="text"
                            className="form-control mb-2"
                            id="userEmailUpdate"
                            onChange={handleUserEmailUpdateChange}
                            value={userEmailUpdate}
                            name="userEmailUpdate"
                            required
                          />

                          <label
                            htmlFor="userPasswordUpdate"
                            className="form-label"
                          >
                            <b>Update Password:</b>
                          </label>

                          <input
                            type="text"
                            className="form-control mb-2"
                            id="userPasswordUpdate"
                            onChange={handleUserPasswordUpdateChange}
                            value={userPasswordUpdate}
                            name="userPasswordUpdate"
                            required
                          />

                          <label
                            htmlFor="userNameUpdate"
                            className="form-label"
                          >
                            <b>Update Name:</b>
                          </label>

                          <input
                            type="text"
                            className="form-control mb-2"
                            id="userNameUpdate"
                            onChange={handleUserNameUpdateChange}
                            value={userNameUpdate}
                            name="userNameUpdate"
                            required
                          />
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </div>
                  ))}
                </div>
                <div className="modal-footer">
                  <p className="bg-info">{userUpdateResult}</p>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* document update model */}
          <div className="modal" id="updateDocuments">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Document Record</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  {documentsFetchs.map((documentsFetch) => (
                    <div key={documentsFetch?.d_id}>
                      <b>Select Transfer Posting Order Record:</b>
                      <p className="bg-success">{documentsFetch?.d_title}</p>
                      <p className="bg-success">{documentsFetch?.d_category}</p>

                      <form onSubmit={updateDocument}>
                        <div className="mb-3 mt-3">
                          <input
                            type="hidden"
                            name="updateIdDocument"
                            id="updateIdDocument"
                            value={documentsFetch?.d_id}
                          />

                          <label htmlFor="documentTitle" className="form-label">
                            <b>Title:</b>
                          </label>

                          <input
                            type="text"
                            className="form-control mb-2"
                            id="docTitleUpdate"
                            onChange={handleDocTitleUpdateChange}
                            value={docTitleUpdate}
                            name="docTitleUpdate"
                            required
                          />
                          <label htmlFor="docFileUpdate">Upload File:</label>
                          <input
                            type="file"
                            name="docFileUpdate"
                            id="docFileUpdate"
                            className="form-control-file"
                            onChange={handleChangeDocUpdateFile}
                            required
                          />

                          <label className="form-label">
                            Document Categories Update:
                          </label>
                          <select
                            className="form-select"
                            value={docCategoryUpdate}
                            id="docCategoryUpdate"
                            name="docCategoryUpdate"
                            onChange={handleCategoryChange}
                            required
                          >
                            <option value="">--Select--</option>
                            <option value="pension">Pension</option>
                            <option value="gpf">GPF</option>
                            <option value="house_requisition">
                              House Requisition
                            </option>
                            <option value="loan_advance">Loan & Advance</option>
                            <option value="hr">HR</option>
                            <option value="other">Other</option>
                          </select>
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </div>
                  ))}
                </div>
                <div className="modal-footer">
                  <p className="text-success">{docUpdateResult}</p>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* contact update model */}
          <div className="modal" id="updateContact">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Contact Record</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  {contactFetchs.map((contactFetch) => (
                    <div key={contactFetch?.contact_id}>
                      <b>Select Gallery Record:</b>
                      <p>contact Id: {contactFetch?.contact_id}</p>
                      <p>contact Category: {contactFetch?.contact_category}</p>
                      <p>
                        contact Designation: {contactFetch?.contact_designation}
                      </p>
                      <p>contact Phone: {contactFetch?.contact_phone}</p>

                      <form onSubmit={handleSubmitContactUpdate}>
                        <input
                          type="hidden"
                          className="form-control"
                          name="updateConid"
                          id="updateConid"
                          value={contactFetch?.contact_id}
                        />

                        <div className="form-group mb-2">
                          <label className="form-label">
                            Update Contact Categories:
                          </label>
                          <select
                            className="form-select"
                            value={updateContactCategory}
                            id="updateContactCategory"
                            name="updateContactCategory"
                            onChange={(e) =>
                              setUpdateContactCategory(e.target.value)
                            }
                            required
                          >
                            <option value="">--Select--</option>

                            <option value="management">Management</option>
                            <option value="administration">
                              Administration
                            </option>
                            <option value="focal">Focal</option>
                          </select>
                        </div>

                        <div className="form-group mb-2">
                          <label className="form-label">
                            Update Designation:
                          </label>
                          <select
                            className="form-select"
                            value={updateContactDesignationCategory}
                            id="updateContactDesignationCategory"
                            name="updateContactDesignationCategory"
                            onChange={(e) =>
                              setUpdateContactDesignationCategory(
                                e.target.value
                              )
                            }
                            required
                          >
                            <option value="">--Select--</option>
                            <option value="Director Accounts">
                              Director Accounts
                            </option>
                            <option value="Deputy Director (Admin)">
                              Deputy Director (Admin)
                            </option>
                            <option value="Deputy Director (Agency)">
                              Deputy Director (Agency)
                            </option>

                            <option value="Account Officer (Admin-I)">
                              Account Officer (Admin-I)
                            </option>
                            <option value="Account Officer (Admin-II)">
                              Account Officer (Admin-II)
                            </option>
                            <option value="Account Officer (Payroll)">
                              Account Officer (Payroll)
                            </option>
                            <option value="Account Officer (Pension)">
                              Account Officer (Pension)
                            </option>
                            <option value="Account Officer (GPF)">
                              Account Officer (GPF)
                            </option>

                            <option value="Focal Person PMDU">
                              Focal Person PMDU
                            </option>
                            <option value="Focal Person Payroll">
                              Focal Person Payroll
                            </option>
                            <option value="Focal Person Pension">
                              Focal Person Pension
                            </option>
                            <option value="Focal Person GPF">
                              Focal Person GPF
                            </option>
                          </select>
                        </div>

                        <div className="form-group mb-2">
                          <label>Phone Number:</label>
                          <input
                            type="text"
                            name="updatePhoneNumber"
                            id="updatePhoneNumber"
                            className="form-control"
                            value={updatePhoneNumber}
                            onChange={(e) =>
                              setUpdatePhoneNumber(e.target.value)
                            }
                            required
                          />
                        </div>

                        <div className="form-group mb-2">
                          <label>Email:</label>
                          <input
                            type="email"
                            name="updateContactEmail"
                            id="updateContactEmail"
                            className="form-control"
                            value={updateContactEmail}
                            onChange={(e) =>
                              setUpdateContactEmail(e.target.value)
                            }
                            required
                          />
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </div>
                  ))}
                </div>
                <div className="modal-footer">
                  <p className="text-success">{updateContactResult}</p>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* gallery update model */}

          <div className="modal" id="updateGallery">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Gallery Record</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  {galleryFetchs.map((galleryFetch) => (
                    <div key={galleryFetch?.g_id}>
                      <b>Select Gallery Record:</b>
                      <p>{galleryFetch?.g_id}</p>
                      <p>{galleryFetch?.g_content}</p>
                      <p>{galleryFetch?.g_category}</p>
                      <p>{galleryFetch?.g_link}</p>
                      <form onSubmit={galleryFormSubmit}>
                        <input
                          type="hidden"
                          className="form-control"
                          name="updateGalleryId"
                          id="updateGalleryId"
                          value={galleryFetch?.g_id}
                        />

                        <div className="form-group mb-2">
                          <label htmlFor="">Gallery Content:</label>
                          <input
                            type="text"
                            name="galleryNameUpdate"
                            id="galleryNameUpdate"
                            className="form-control"
                            value={galleryNameUpdate}
                            onChange={handleGalleryNameChange}
                            
                          />
                        </div>

                        <div className="form-group mb-2">
                          <label htmlFor="">Gallery Category:</label>
                          <select
                            className="form-select"
                            value={galleryCategoryUpdate}
                            id="galleryCategoryUpdate"
                            name="galleryCategoryUpdate"
                            onChange={(e) => setGalleryCategoryUpdate(e.target.value)}
                            required
                          >
                            <option value="">--Select--</option>
                            <option value="gallery">Gallery</option>
                            <option value="desktop">Desktop</option>
                          </select>
                        </div>

                        <div className="form-group mb-2">
                          <label htmlFor="">Gallery Link:</label>
                          <input
                            type="text"
                            name="galleryLinkUpdate"
                            id="galleryLinkUpdate"
                            className="form-control"
                            value={galleryLinkUpdate}
                            onChange={(e) => setGalleryLinkUpdate(e.target.value)}
                            
                          />
                        </div>

                        <div className="form-group mb-2">
                          <label htmlFor="image">Upload Image:</label>
                          <input
                            type="file"
                            name="updateImageGallery"
                            id="updateImageGallery"
                            className="form-control-file"
                            onChange={handleGalleryImageChange}
                            required
                          />
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </div>
                  ))}
                </div>
                <div className="modal-footer">
                  <p className="text-success">{galleryResult}</p>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
