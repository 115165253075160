import dwn from "../img/download.png";
import Notification2 from "../download/OTHERS/Pkg-Rules.pdf";
import Notification3 from "../download/OTHERS/Recct-Rules-JA.pdf";
import Notification4 from "../download/OTHERS/SA-Recct-Rules.pdf";
import Notification5 from "../download/OTHERS/Scales2022-OM.pdf";
import Notification6 from "../download/OTHERS/TT-Policy.pdf";
import { useState, useEffect } from "react";
import axios from "axios";

export const Notification = () => {

  const [transferPostingDatas, setTransferPostingDatas] = useState([]);
  const [transferNotFound, setTransferNotFound] = useState('');

  useEffect(()=>{
    getAllNotification();
  }, [transferPostingDatas]);

  const getAllNotification = async () => {
    //https://dappo.gov.pk/Dappo/transferPosting_api/transferPostingAll_api.php
    //const url = `http://127.0.0.1:8080/dappo/dbconnect/transferPosting_api/transferPostingAll_api.php`;

    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/transferPosting_api/transferPostingAll_api.php`
    );

    if (res.data.status === false) {
      setTransferNotFound(res.data.message);
    } else {
      setTransferPostingDatas(res.data);
    }
    //console.log(res.data.result);
    //console.log(res.data);
    //setTransferPostingDatas(res.data);
  };

  return (
    <>
      <div className="container mt-3">
        <div className="text-center">
          <div className="card">
            <div className="card-header ">
              <strong>Notifications</strong>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container mt-3">
          <div className="text-center">
            <strong>
              This section provides Notifications related to this Directorate
            </strong>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <div className="container">
        <div id="accordion">
          <div class="card">
            <div class="card-header">
              <a class="btn" data-bs-toggle="collapse" href="#collapseOne">
                <i class="bi bi-chevron-down"></i><strong> TRANSFER POSTING</strong>
              </a>
            </div>
            <div
              id="collapseOne"
              class="collapse"
              data-bs-parent="#accordion"
            >
              <div class="card-body">
                    <div className="table-responsive">

                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Date & Time</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    <p className="text-danger">{transferNotFound}</p>
                    {transferPostingDatas.map((transferPostingData) => (
                      <tr key={transferPostingData?.transferPosting_id}>
                      <td>{transferPostingData?.transferPosting_title}</td>
                      <td>{transferPostingData?.transferPosting_date}</td>
                      <td><a href={`https://dappo.gov.pk/Dappo/upload/${transferPostingData?.transferPosting_fileName}`}>
                      <img src={dwn} className="img-fluid" /> </a>
                      </td>
                    </tr>
                    ))}
                    
          
                  </tbody>
                </table>
                    </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <a
                class="collapsed btn"
                data-bs-toggle="collapse"
                href="#collapseTwo"
              >
                <i class="bi bi-chevron-down"></i><strong>OTHER</strong>
              </a>
            </div>
            <div id="collapseTwo" class="collapse show" data-bs-parent="#accordion">
              <div class="card-body">
                <div className="table-responsive">

                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Date</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>DPC/DSC Policy</td>
                      <td>05-09-2019</td>
                      <td>
                      <a href={Notification2}>
                          <img src={dwn} className="img-fluid" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Assistant Package</td>
                      <td>29-11-2022</td>
                      <td>
                      <a href={Notification3}>
                          <img src={dwn} className="img-fluid" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Recruitment Rules for Senior Auditors</td>
                      <td>30-07-2019</td>
                      <td>
                      <a href={Notification4}>
                          <img src={dwn} className="img-fluid" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Revised BPS 2022</td>
                      <td>01-07-2022</td>
                      <td>
                        <a href={Notification5}>
                          <img src={dwn} className="img-fluid" />
                        </a></td>
                    </tr>
                    <tr>
                      <td>Temporary Transfer Policy</td>
                      <td>07-01-2020</td>
                      <td>
                        <a href={Notification6}>
                          <img src={dwn}  className="img-fluid"/>
                        </a></td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};
