import React, { useState } from "react";
import axios from "axios";
// import { userIdContext } from "../App";
// import { useContext } from "react";


const MarqueeModel = () => {

  //const {userId} = useContext(userIdContext)

  const userId = localStorage.getItem('userId')
    
  const [marqueeNew, setMarqueeNew] = useState('')

    const [result, setResult] = useState('')

    const handleInput = (e) => {
        setMarqueeNew(e.target.value);
    }

    const submitForm = (e) => {
        e.preventDefault();
        const sendData = {
          id: userId,
          marqueeTitle: marqueeNew
        }
        console.log(sendData)


        axios.post(`https://dappo.gov.pk/Dappo/marquee_api/marqueeInsert_api.php/`,sendData)
        .then((result)=>{
          //console.log(result.data);
          setResult(result.data.message)
        })

        setMarqueeNew('');
    }

  return (
    <>
      <div>
        <button type="button" className="btn btn-primary mb-3" data-bs-toggle="modal" data-bs-target="#marqueeForm">
    Add Marquee
  </button>
</div>

<div className="modal" id="marqueeForm">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">

     
      <div className="modal-header">
        <h4 className="modal-title">Add Marquee</h4> 
        <br />
        
        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
      </div>

      
      <div className="modal-body">
        <form onSubmit={submitForm}>
        {/* <input type='hidden' className="form-control" name='mid' id='mid' value = {userId} /> */}
  <div className="mb-3 mt-3">
    <label  className="form-label"><b>Marquee Title:</b></label>
    {/* <input
                  type="hidden"
                  className="form-control"
                  name="marid"
                  id="marid"
                  value={userId}
                /> */}
    <input type="text" className="form-control" onChange={handleInput} value={marqueeNew} id="marqueeNew" placeholder="Enter Marquee" name="marqueeTitle" required />
  </div>
  

  <button type="submit" className="btn btn-primary">Submit</button>
</form>
      </div>

    
      <div className="modal-footer">
      <h4 className="text-success">{result}</h4>
        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
      
    </div>
    </>
  )
}

export default React.memo(MarqueeModel)
