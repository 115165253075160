import dwn from "../img/download.png";
 import calculator from "../img/calculator-icon.png";

// import AcrBps15 from "../download/ACRs & PERs/ACR-BPS-1-TO-15.pdf";
// import AcrBps16 from "../download/ACRs & PERs/ACR-BPS-16.pdf";
// import GpfApp from "../download/GPF/APPLICATION-FOR-GPF-ACCOUNT.pdf";
// import Gpf10 from "../download/GPF/GPF-10-FORM.pdf";
// import GpfNomination from "../download/GPF/NOMINATION-FOR-GPF.pdf";
// import HrAPP from "../download/HOUSE REQUSITION/HR-APPLICATION.pdf";
// import HrCl from "../download/HOUSE REQUSITION/HR-CHECKLIST.pdf";
// import HrIr from "../download/HOUSE REQUSITION/HR-INSPECTION-REPORT.pdf";
// import HrLa from "../download/HOUSE REQUSITION/HR-LEASE-AGREEMENT.pdf";
// import AdvCyc from "../download/LOANS & ADVANCES/CYCLE-ADV.pdf";
// import AdvGpf from "../download/LOANS & ADVANCES/GPF-ADV.pdf";
// import AdvHba from "../download/LOANS & ADVANCES/HBA-ADV.pdf";
// import AdvMc from "../download/LOANS & ADVANCES/MC-ADV.pdf";
// import CaReport from "../download/OTHERS/CA-REPORT.pdf";
// import CrReport from "../download/OTHERS/CR-REPORT.pdf";
// import Declar from "../download/OTHERS/DECLARATION.pdf";
// import LeaveApp from "../download/OTHERS/LEAVE-APP.pdf";
// import MedCardForm from "../download/OTHERS/MEDICAL-CARD-FORM.pdf";
// import Notification1 from "../download/OTHERS/BF-GI-Fund.pdf";

// import pension_csr2019 from "../download/pension/CSR_25_Revised_2019.pdf";
// import manual from "../download/pension/A_Manual.pdf";
// import dcsForm from "../download/pension/dcs_form.pdf";
// import dcsDet from "../download/pension/dcsDet.pdf";
// import life from "../download/pension/life.pdf";
// import pension from "../download/pension/pension.pdf";
// import Pensioncidform from "../download/pension/Pensioncidform.pdf";
// import restoration from "../download/pension/restoration_undertaking.pdf";
// import sbpcircular from "../download/pension/sbpcircular.pdf";
import { useEffect, useState } from "react";
import axios from "axios";

export const Downloads = () => {

  const [pensionNotFound, setPensionNotFound] = useState('');
  const [pensionDocs, setPensionDocs] = useState([]);

  const [gpfNotFound, setGpfNotFound] = useState('');
  const [gpfDocs, setGpfDocs] = useState([]);

  const [houseReqNotFound, setHouseReqNotFound] = useState('');
  const [houseReqDocs, sethouseReqDocs] = useState([]);

  const [loanAdvNotFound, setLoanAdvNotFound] = useState('');
  const [loanAdvDocs, setLoanAdvDocs] = useState([]);

  const [hrDocs, setHrDocs] = useState([]);
  const [hrNotFound, setHrNotFound] = useState('');

  const [otherDocs, setOtherDocs] = useState([]);
  const [otherNotFound, setOtherNotFound] = useState('');

  useEffect(() => {
    axios
      .get(
        "https://dappo.gov.pk/Dappo/documents_api/docPension_api.php"
      )
      .then((res) => {
       // console.log(res.data);

      if (res.data.status === false) {
        setPensionNotFound(res.data.message)
      } else {
        setPensionDocs(res.data);
      }

        //setPensionDocs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("https://dappo.gov.pk/Dappo/documents_api/docGpf_api.php")
      .then((res) => {
        //console.log(res.data);
        if (res.data.status === false) {
          setGpfNotFound(res.data.message)
        } else {
          setGpfDocs(res.data);
        }
        
       // setGpfDocs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        "https://dappo.gov.pk/Dappo/documents_api/docHouseReq_api.php"
      )
      .then((res) => {
       // console.log(res.data);
      if (res.data.status === false) {
        setHouseReqNotFound(res.data.message)
      } else {
        sethouseReqDocs(res.data);
      }
        //sethouseReqDocs(res.data);
      
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        "https://dappo.gov.pk/Dappo/documents_api/docLoanAdv_api.php"
      )
      .then((res) => {
        //console.log(res.data);
        if (res.data.status === false) {
          setLoanAdvNotFound(res.data.message)
        } else {
          setLoanAdvDocs(res.data);
        }
        //setLoanAdvDocs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("https://dappo.gov.pk/Dappo/documents_api/docHr_api.php")
      .then((res) => {
        //console.log(res.data);
        if (res.data.status === false) {
          setHrNotFound(res.data.message)
        } else {
          setHrDocs(res.data);
        }
        //setHrDocs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        "https://dappo.gov.pk/Dappo/documents_api/docOther_api.php"
      )
      .then((res) => {
        //console.log(res.data);
        if (res.data.status === false) {
          setOtherNotFound(res.data.message)
        } else {
          setOtherDocs(res.data);
        }
        //setOtherDocs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    //getPensionDocument();
  }, []);

  

  return (
    <>
      <div className="container mt-3">
        <div className="text-center">
          <div className="card">
            <div className="card-header ">
              <strong>Downloads</strong>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div className="text-center">
            <strong>
              This section provides necessary documents and procedures related
              to this Directorate
            </strong>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <div className="card  my-3">
              <div className="card-header bg-info">
                <b className="text-light">Pension</b>
              </div>
              <div className="card-body">
              <div class="table-responsive">

                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Pension Calculator</td>
                      <td><a
                          href="https://agpr.gov.pk/Pensioncal/pencal.html"
                          >
                          <img src={calculator}  className ="img-fluid" />
                        </a></td>
                    </tr>
                    <p className="text-danger">{pensionNotFound}</p>

                    {pensionDocs?.map((pensionDoc) => (
                      <tr key={pensionDoc?.d_id}>
                        <td>{pensionDoc?.d_title}</td>
                        <td>
                          <a
                            href={`https://dappo.gov.pk/Dappo/upload/${pensionDoc?.d_fileName}`}
                            >
                            <img src={dwn} className ="img-fluid" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <div className="card  my-3">
              <div className="card-header bg-primary">
                <b className="text-light">Loans & Advance</b>
              </div>
              <div className="card-body">
                <table className="table table-striped table-hover table-responsive-sm">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                  <p className="text-danger">{loanAdvNotFound}</p>
                    {loanAdvDocs?.map((loanAdvDoc) => (
                      <tr key={loanAdvDoc?.d_id}>
                        <td>{loanAdvDoc?.d_title}</td>
                        <td>
                          <a
                            href={`https://dappo.gov.pk/Dappo/upload/${loanAdvDoc?.d_fileName}`}
                          >
                            <img src={dwn} className ="img-fluid" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          
        {/* </div>
        <div className="row"> */}
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <div className="card  my-3">
              <div className="card-header bg-success">
                <b className="text-light">House Requisition</b>
              </div>
              <div className="card-body">
                <table className="table table-striped table-hover table-responsive-sm">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                  <p className="text-danger">{houseReqNotFound}</p>
                    {houseReqDocs?.map((houseReqDoc) => (
                      <tr key={houseReqDoc?.d_id}>
                        <td>{houseReqDoc?.d_title}</td>
                        <td>
                          <a
                            href={`https://dappo.gov.pk/Dappo/upload/${houseReqDoc?.d_fileName}`}
                          >
                            <img src={dwn} className ="img-fluid" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        {/* </div>

        <div className="row"> */}
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <div className="card  my-3">
              <div className="card-header bg-secondary">
                <b className="text-light">General Provident Fund (GPF)</b>
              </div>
              <div className="card-body">
                <table className="table table-striped table-hover table-responsive-sm">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                  <p className="text-danger">{gpfNotFound}</p>
                    {gpfDocs?.map((gpfDoc) => (
                      <tr key={gpfDoc?.d_id}>
                        <td>{gpfDoc?.d_title}</td>
                        <td>
                          <a
                            href={`https://dappo.gov.pk/Dappo/upload/${gpfDoc?.d_fileName}`}
                          >
                            <img src={dwn} className ="img-fluid" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        {/* </div>
        <div className="row"> */}
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <div className="card  my-3">
              <div className="card-header bg-dark">
                <b className="text-light">HR</b>
              </div>
              <div className="card-body">
                <table className="table table-striped table-hover table-responsive-sm">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                  <p className="text-danger">{hrNotFound}</p>
                    {hrDocs?.map((hrDoc) => (
                      <tr key={hrDoc?.d_id}>
                        <td>{hrDoc?.d_title}</td>
                        <td>
                          <a
                            href={`https://dappo.gov.pk/Dappo/upload/${hrDoc?.d_fileName}`}
                          >
                            <img src={dwn} className ="img-fluid" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <div className="card my-3">
              <div className="card-header bg-dark">
                <b className="text-light">Others</b>
              </div>
              <div className="card-body">
                <table className="table table-striped table-hover table-responsive-sm">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                  <p className="text-danger">{otherNotFound}</p>
                    {otherDocs?.map((otherDoc) => (
                      <tr key={otherDoc?.d_id}>
                        <td>{otherDoc?.d_title}</td>
                        <td>
                          <a
                            href={`https://dappo.gov.pk/Dappo/upload/${otherDoc?.d_fileName}`}
                          >
                            <img src={dwn} className ="img-fluid" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      

      {/* <div className="container mt-3">
        <div className="text-center">
          <div className="card">
            <div className="card-header ">
              <strong>Downloads</strong>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="container mt-3">
        <div className="text-center">
          <strong>
            This section provides necessary documents and procedures related to
            this Directorate
          </strong>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="container">
        <div id="accordion">
          <div className="card">
            <div className="card-header">
              <a className="btn" data-bs-toggle="collapse" href="">
                <strong>FORMS</strong>
              </a>
            </div>
            <div
              id="collapseOne"
              className="collapse show"
              data-bs-parent="#accordion"
            >
              <div className="card-body">
                <table className="table table-striped table-hover table-responsive-sm">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Category</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Pension Calculator</td>
                      <td>PENSION </td>
                      <td>
                        <a
                          href="https://agpr.gov.pk/Pensioncal/pencal.html"
                          target="_blank"
                        >
                          <img src={calculator} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>CSR-25(Revised-2019) </td>
                      <td>PENSION </td>
                      <td>
                        <a href={pension_csr2019}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        SBP circular along with Finance Division Letter for
                        biometric verification of pensioners
                      </td>
                      <td>PENSION </td>
                      <td>
                        <a href={sbpcircular}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Pensioner ID Card Circular and Request Form </td>
                      <td>PENSION </td>
                      <td>
                        <a href={Pensioncidform}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Undertaking specimen for restoration </td>
                      <td>PENSION </td>
                      <td>
                        <a href={restoration}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>DCS Guidelines</td>
                      <td>PENSION </td>
                      <td>
                        <a href={dcsDet}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>DCS Option Form</td>
                      <td>PENSION </td>
                      <td>
                        <a href={dcsForm}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Life Certificate (Required by Bank)</td>
                      <td>PENSION </td>
                      <td>
                        <a href={life}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Pension Papers (undertaking, Specimen Signature, Thumb
                        Impressions and List of Family members)
                      </td>
                      <td>PENSION </td>
                      <td>
                        <a href={pension}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>A Manual on Pension Procedures(NEW)</td>
                      <td>PENSION </td>
                      <td>
                        <a href={manual}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>APPLICATION FOR GPF ACCOUNT </td>
                      <td>GPF </td>
                      <td>
                        <a href={GpfApp}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>GPF-10 FORM </td>
                      <td>GPF </td>
                      <td>
                        <a href={Gpf10}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>GPF NOMINATION </td>
                      <td>GPF </td>
                      <td>
                        <a href={GpfNomination}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>APPLICATION FOR HOUSE REQUISITION </td>
                      <td>HOUSE REQUISITION </td>
                      <td>
                        <a href={HrAPP}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>CHECK LIST FOR HOUSE REQUISITION </td>
                      <td>HOUSE REQUISITION </td>
                      <td>
                        <a href={HrCl}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>INSPECTION REPORT FOR HOUSE REQUISITION </td>
                      <td>HOUSE REQUISITION </td>
                      <td>
                        <a href={HrIr}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>LEASE AGREEMENT FOR HOUSE REQUISITION </td>
                      <td>HOUSE REQUISITION </td>
                      <td>
                        <a href={HrLa}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>APPLICATION FOR CYCLE ADVANCE </td>
                      <td>LOANS & ADVANCES </td>
                      <td>
                        <a href={AdvCyc}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>APPLICATION FOR GPF ADVANCE </td>
                      <td>LOANS & ADVANCES </td>
                      <td>
                        <a href={AdvGpf}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>APPLICATION FOR HOUSE BUILDING ADVANCE </td>
                      <td>LOANS & ADVANCES </td>
                      <td>
                        <a href={AdvHba}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>APPLICATION FOR M/CYCLE/CAR ADVANCE </td>
                      <td>LOANS & ADVANCES </td>
                      <td>
                        <a href={AdvMc}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>ACR BPS 1-15 </td>
                      <td>HR </td>
                      <td>
                        <a href={AcrBps15}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>ACR BPS 16 </td>
                      <td>HR</td>
                      <td>
                        <a href={AcrBps16}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>CHARGE ASSUMED REPORT </td>
                      <td>HR</td>
                      <td>
                        <a href={CaReport}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>CHARGE RELINQUISHED REPORT </td>
                      <td>HR</td>
                      <td>
                        <a href={CrReport}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>DECLARATION OF ASSET & INCOME </td>
                      <td>HR</td>
                      <td>
                        <a href={Declar}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>LEAVE APPLICATION </td>
                      <td>HR</td>
                      <td>
                        <a href={LeaveApp}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>MEDICAL CARD FORM </td>
                      <td>HR</td>
                      <td>
                        <a href={MedCardForm}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Benevolent Fund </td>
                      <td>HR</td>
                      <td>
                        <a href={Notification1}>
                          <img src={dwn} />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
          {/*

          <div className="card">
            <div className="card-header">
              <a className="collapsed btn" data-bs-toggle="collapse" href="">
                CIRCULARS
              </a>
            </div>
            <div
              id="collapseTwo"
              className="collapse show"
              data-bs-parent="#accordion"
            >
              <div className="card-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Catagory</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>



                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <a className="collapsed btn" data-bs-toggle="collapse" href="">
                PROCEDURES
              </a>
            </div>
            <div
              id="collapseThree"
              className="collapse show"
              data-bs-parent="#accordion"
            >
              <div className="card-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Catagory</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>


                  </tbody>
                </table>
              </div>
            </div>
            </div>

  */}
        </div>
      
    </>
  );
};
