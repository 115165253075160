import React from "react";
import { Marquee } from "../components/Marquee";
// import { Carousel } from '../components/Carousel';
// import { MainContent } from '../components/MainContent';
import Map from "../components/Map";
import { useState, useEffect } from "react";
import axios from "axios";
// import Image from "../img/dpa.jpg";
// import img1 from "../img/ddadm.jpeg";
// import img2 from "../img/ddaf.jpg";
import img3 from "../img/HR-LAB/4.jpeg";
import Match from "../img/match.png";
import "../styles/scrollableCard.css";

export const Home = () => {
  const [officers, setOfficers] = useState([]);
  const [officerNotFound, setOfficerNotFound] = useState('');

  const [carousels, setCarousels] = useState([]);
  const [carouselNotFound, setCarouselNotFound] = useState('');

  const [topStoryImages, setTopStoryImages] = useState([]);
  const [topStoryNotFound, setTopStoryNotFound] = useState('');

  useEffect(() => {
    getDataOfficer();
    getDataCarousel();
    getDataTopStory();
  }, []);

  const getDataOfficer = async () => {
    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/officer_api/officerAll_api.php`
    );

    if (res.data.status === false) {
      setOfficerNotFound(res.data.message)
    } else {
      setOfficers(res.data);
    }

    //console.log(res.data.result);
    //console.log(res.data);
    //setOfficers(res.data);
  };

  const getDataCarousel = async () => {
    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/carousel_api/carouselAll_api.php`
    );

    //console.log(res.data.result);
    //console.log(res.data);
    if (res.data.status === false) {
      setCarouselNotFound(res.data.message)
    } else {
      setCarousels(res.data);
    }

    //setCarousels(res.data);
  };

  const getDataTopStory = async () => {
    const res = await axios.get(
      `https://dappo.gov.pk/Dappo/gallery_api/galleryTopStory_api.php`
    );

    //console.log(res.data.result);
    //console.log(res.data);
    if (res.data.status === false) {
      setTopStoryNotFound(res.data.message)
    } else {
      setTopStoryImages(res.data);
    }
    setTopStoryImages(res.data);
  };

  return (
    <>
      <Marquee
        title="Latest News: "
        direction="left"
        height="25rem"
        width="93%"
      />

      {/* <Carousel carousels = {carousels} /> */}

      <div className="cantainer">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={require("../img/building.jpg")}
                className="d-block w-100"
                style={{ height: "30rem" }}
                alt=""
              />
            </div>
            <div className="carousel-item">
              <img
                src={require("../img/dpa2.jpg")}
                className="d-block w-100"
                style={{ height: "30rem" }}
                alt=""
              />
            </div>
            <p className="text-danger">{carouselNotFound}</p>
            {carousels.map((carousel) => (
              <div className="carousel-item" key={carousel?.carousel_id}>
                <a href={carousel?.carousel_link}>
                  <img
                    src={`https://dappo.gov.pk/Dappo/upload/${carousel?.carousel_image}`}
                    className="d-block w-100"
                    style={{ height: "30rem" }}
                    alt=""
                  />
                </a>
              </div>
            ))}
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div className="container mt-2">
        <div className="row">
          <div className="col-lg-4 col-md-6  col-sm-6 pt-2 ">
            <div className="border border-success py-3 px-3">
              <div
                className="card mb-4 shadow-lg text-white"
                style={{ backgroundColor: "#004D25", height: "5rem" }}
              >
                <h3 className="p-4 d-flex justify-content-center">OFFICERS</h3>
              </div>
              <p className="text-danger">{officerNotFound}</p>
              {officers.map((officer) => (
                <div
                  className="card mb-4 shadow-lg bg-light"
                  key={officer.officer_id}
                  style={{ height: 180 }}
                >
                  <div className="row">
                    <div className="col">
                      <img
                        className="img-fluid rounded-start rounded-circle"
                        src={`https://dappo.gov.pk/Dappo/upload/${officer?.officer_image}`}
                        alt="Card image"
                        style={{ width: 200, height: 180 }}
                      />
                    </div>
                    <div className="col">
                      <div className="card-body text-center">
                        <h5 className="card-title ">{officer.officer_name}</h5>
                        <p className="card-text">
                          {officer.officer_designation}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* <Profile
                name="Qamar Riaz"
                designation="Director Accounts"
                src={Image}
              />
              <Profile
                name="Hassan Shahid"
                designation="Deputy Director (ADMN)"
                src={img1}
              />
              <Profile
                name="Habib Ur Rehman"
                designation="Deputy Director (AF)"
                src={img2}
              /> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 pt-2">
            <div className="border border-success py-3 px-3">
              <div
                className="card mb-4 shadow-lg text-white"
                style={{ backgroundColor: "#004D25", height: "5rem" }}
              >
                <h3 className="p-4 d-flex justify-content-center">
                  ASSERTIONS
                </h3>
              </div>
              <div
                className="card text-dark shadow-lg mb-4"
                style={{ height: 180 }}
              >
                <div className="card-header">
                  <strong>Vision</strong>
                </div>
                <div className="card-body">
                  <div className="scrollable">
                    <p className="card-text">
                      To excel in providing accurate and timely data,
                      demonstrate integrity in Post Audit function and foster a
                      keen focus on providing true & fair picture of PPOD
                      accounts.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="card text-dark shadow-lg mb-4"
                style={{ height: 180 }}
              >
                <div className="card-header">
                  <strong>Mission</strong>
                </div>
                <div className="card-body">
                  <div className="scrollable">
                    <p className="card-text">
                      Ensuring Timely, Effective & Error free Financial
                      reporting of Pakistan Post Office Department to stake
                      holders.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="card text-dark shadow-lg mb-4"
                style={{ height: 180 }}
              >
                <div className="card-header">
                  <strong>Role of DAPPO</strong>
                </div>
                <div className="card-body">
                  <div className="scrollable">
                    <p className="card-text">
                      Director Accounts Pakistan Post Office (DAPPO) Lahore is a
                      Subordinate Department of Ministry of Finance under the
                      Administrative Control of the Controller General of
                      Accounts (CGA) mainly responsible for Maintaining Accounts
                      of Pakistan Post Office Department (PPOD) and rendering
                      financial statements to Postal authorities, CGA Islamabad,
                      AGPR Islamabad and D.G. PT & T Audit Lahore.{" "}
                    </p>
                    <p>
                      This office also serves as a Central Record Office. The
                      life of record varies from 18 months to 30 years as per
                      provisions of �Manual of Standing Orders�. At the time of
                      departmentalization in 1981, not only the accounting work
                      was transferred but also the auditoria functions in the
                      form of pre-checks and post-checks, as were previously
                      done in the A.G. PT&T, Lahore, were entrusted to this
                      office as contemplated in para 15 of Accounting Procedure
                      in Auditor General�s O.M.No.985 M&M/39-78 dated
                      29.12.1980.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 pt-2">
            <div
              className="border border-success py-3 px-3"
              style={{ height: "46.9rem" }}
            >
              <div
                className="card mb-4 shadow-lg text-white"
                style={{ backgroundColor: "#004D25", height: "5rem" }}
              >
                <h3 className="p-4 d-flex justify-content-center">
                  TOP STORIES
                </h3>
              </div>
              <p className="text-danger">{topStoryNotFound}</p>
              {topStoryImages.map((topStoryImage) => (

                <div
                  className="card text-dark shadow-lg mb-4"
                  style={{ height: "16rem" }}
                >
                  <div className="card-header d-flex justify-content-center" key={topStoryImage?.g_id}>
                   <a href={topStoryImage?.g_link}>
                     <img
                      src={`https://dappo.gov.pk/Dappo/upload/${topStoryImage?.g_fileName}`}
                      className="d-block w-100"
                      style={{ height: "10rem" }}
                      alt=""
                    />
                  </a>
                  </div>
                  <div className="card-body text-center">
                    <div className="scrollable">
                      <p className="card-text bg-success text-white">
                        {topStoryImage?.g_content}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div
                className="card text-dark shadow-lg mb-4"
                style={{ height: "20rem" }}
              >
                <div className="card-header d-flex justify-content-center">
                  <img
                    src={img3}
                    className="img-fluid"
                    style={{ width: "18rem" }}
                  />
                </div>
                <div className="card-body text-center">
                  <div className="scrollable">
                    <p className="card-text">
                      Inauguration of newly established HR LAB in DAPPO, Lahore.
                    </p>
                  </div>
                </div>
              </div> */}

              {/* <div
                className="card text-dark shadow-lg mb-4"
                style={{ height: "15rem" }}
              >
                <div className="card-header d-flex justify-content-center">
                  <img src={Match} style={{ width: "8rem" }} />
                </div>
                <div className="card-body text-center">
                  <div className="scrollable">
                    <p className="card-text">Friendly cricket match.</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <Map />
    </>
  );
};
