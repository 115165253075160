import React from 'react'
import logo2 from '../img/logo2.png';
const Header = () => {
  return (
    <>
    <div className="container-fluid">
      <div className="row">   
        <img className="float-start"
          src={logo2} />
      </div>
    </div>
    </>
  )
}

export default Header
