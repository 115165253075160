import axios from "axios";
import { useState, useEffect } from "react";
import {Link} from "react-router-dom";


export const Marquee = (props) => {

  
  const [marqu, setMarqu] = useState([]);
  const [marqueeNotFound, setMarqueeNotFound] = useState('');

  const [isHovering, setIsHovering] = useState(false);

  function handleMouseEnter() {
    setIsHovering(true);
  }

  function handleMouseLeave() {
    setIsHovering(false);
  }

  useEffect(()=>{

    getMarquee();
    
  
  },[])

  const getMarquee = async () => {
    const res = await axios.get(`https://dappo.gov.pk/Dappo/marquee_api/marqueeAll_api.php`)
    
    //console.log(res.data);
    if (res.data.status === false) {
      setMarqueeNotFound(res.data.message)
    } else {
      setMarqu(res.data);
    }

    //setMarqu(res.data);

  }

  return (
    <>
    <div className="container-fluid-xxl">
      
      <div className="d-flex">
        <p className="text-bg-success position-absolute" style ={{height:"25rem", paddingRight:"1rem"}}>
         {props.title}
        </p>
        <marquee
          className="float-end bg-success text-light" style={{marginLeft:"6.5rem"}}
          width={props.width}
          direction={props.direction}
          height={props.height}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          behavior={isHovering ? "pause" : "scroll"}
         
        >
          <ul style={{ padding:0, margin:0 }}>
              {marqueeNotFound}   
              { marqu.map(marque => ( 
              <li style={{ display:"inline-block", marginRight:"8rem"}}>
              <span style={{marginRight:"0.5rem"}}>o</span> <Link to="/gallery" className="text-light">
                {marque?.marquee_title}
              </Link>
              </li>
               ))}
          </ul>

          {/* <Link to="/gallery" className="text-light">{marqu.map(marque =>(
            <div className="">{marque?.marquee_title}</div>
          ))}</Link> 
          list-style-type: circle
          */}
         
        </marquee>
      </div>
    </div>
    </>
  );
};