
import img1 from "../img/HR-LAB/1.jpeg";
import { useState, useEffect } from "react";
import axios from "axios";
//import img2 from '../img/HR-LAB/2.jpeg';
// import img3 from "../img/HR-LAB/3.jpeg";
// import img4 from "../img/HR-LAB/4.jpeg";
// import img5 from "../img/HR-LAB/5.jpeg";
// import img6 from "../img/HR-LAB/6.jpeg";
// import img7 from "../img/HR-LAB/7.jpeg";
// import img8 from "../img/HR-LAB/8.jpeg";
// import img9 from "../img/HR-LAB/9.jpeg";
// import img10 from "../img/HR-LAB/10.jpeg";

const Gallery = () => {
 
  const [galleryDatas, setGalleryData] = useState([]);
  const [galleryNotFound, setGalleryNotFound] = useState('');
  
  useEffect(()=>{
    const getDataGallery = async () => {
      const res = await axios.get(
        `https://dappo.gov.pk/Dappo/gallery_api/galleryAll_api.php`
      );

      if (res.data.status === false) {
        setGalleryNotFound(res.data.message)
      } else {
        setGalleryData(res.data);
      }
      //console.log(res.data)
      //setGalleryData(res.data);
    };
    getDataGallery();
  },[])

  return (
    <>
      <div className="container mt-3">
        <div className="text-center">
          <div className="card">
            <div className="card-header ">
              <strong>Gallery</strong>
            </div>
          </div>
        </div>
      </div>


      <br></br>
      <br></br>
      <div className="container">
        <div className="row">
          <div className="col-xs-* col-sm-* col-md-* col-lg-* col-xl-* col-xxl-*">
            <p className="text-danger">{galleryNotFound}</p>
        {galleryDatas.map((galleryData) => (

      <div className="card mx-auto my-3" key={galleryData?.g_id}>
        <img
          className="card-img-top img-fluid"
          src={`https://dappo.gov.pk/Dappo/upload/${galleryData?.g_fileName}`}
          alt="Card image"
          style={{ width: "100%" }}
        />
        <div className="card-body bg-success text-white">
          <p className="card-text">
            <strong>{galleryData?.g_content}</strong>
          </p>
        </div>
      </div>
        ))}
          </div>
          
        </div>

        {/* <div id="accordion">
          <div className="card">
            <div className="card-header">
              <strong>Inauguration of newly established HR LAB.</strong>
            </div>
            <div
              id="collapseOne"
              className="collapse show"
              data-bs-parent="#accordion"
            >
              <div className="card-body">
                <div className="container">
                  <div className="row gap-2">
                    <img src={img1} />
                  
                    <img src={img3} />
                    <img src={img4} />
                    <img src={img5} />
                    <img src={img6} />
                    <img src={img7} />
                    <img src={img8} />
                    <img src={img9} />
                    <img src={img10} />
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div> */}
      </div>
    </>
  );
};

export default Gallery;
