import React, { useState } from 'react';
import axios from 'axios';

function UserNewForm() {

  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const [userNewResult, setUserNewResult] = useState('')

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleUserPasswordChange = (event) => {
    setUserPassword(event.target.value);
  };

  const handleUserFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('userName',userName );
    formData.append('userEmail', userEmail);
    formData.append('userPassword', userPassword);

    console.log(formData)
    

    try {
      const response = await axios.post('https://dappo.gov.pk/Dappo/users_api/userinsert_api.php/', formData);
      
      console.log(response.data.message);
      
      setUserName('');
      setUserEmail('');
      setUserPassword('');

       // You can do something with the response data here
      setUserNewResult(response.data.message)

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>

     
<button type="button" className="btn btn-primary mb-3" data-bs-toggle="modal" data-bs-target="#addUser">
  Add User
</button>


<div className="modal" id="addUser">
  <div className="modal-dialog">
    <div className="modal-content">

     
      <div className="modal-header">
        <h4 className="modal-title">
            User
        </h4>
        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
      </div>

     
      <div className="modal-body">
      <form onSubmit={handleUserFormSubmit}>
      
      <div className="form-group mb-2">
        <label htmlFor="">User Name:</label>
        <input type="text" name="userName" id="userName" className="form-control" value={userName} onChange={handleUserNameChange} required />
      </div>
      <div className="form-group mb-2">
        <label htmlFor="">User Email:</label>
        <input type="email" name="userEmail" id="userEmail" className="form-control" value={userEmail} onChange={handleUserEmailChange} required />
      </div>

      <div className="form-group mb-2">
        <label htmlFor="">Password :</label>
        <input type="password" name="userPassword" id="userPassword" className="form-control" value={userPassword} onChange={handleUserPasswordChange} required />
      </div>
      
      <button type="submit" className="btn btn-primary">Submit</button>
    
    </form>
      </div>

     
      <div className="modal-footer">
        <p className='bg-info'>{userNewResult}</p>
        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>

    

    </>
  );
}

export default React.memo(UserNewForm);
