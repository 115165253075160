import React, { useState } from "react";
import axios from "axios";
//import { userIdContext } from "../App";
//import { useContext } from "react";

const GalleryForm = () => {
  //const {userId} = useContext(userIdContext);
  const userId = localStorage.getItem("userId");
  //console.log(userId)

  const [galleryName, setGalleryName] = useState("");
  const [galleryCategory, setGalleryCategory] = useState("");
  const [galleryLink, setGalleryLink] = useState("");

  const [imageGallery, setImageGallery] = useState(null);
  const [galleryResult, setGalleryResult] = useState("");

  const handleOffierNameChange = (event) => {
    setGalleryName(event.target.value);
  };

  const handleImageChange = (event) => {
    setImageGallery(event.target.files[0]);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

  // let timeout;

  // function myFunction() {
  //   timeout = setTimeout(alertFunc, 3000);  
  // }

  // function alertFunc() {
  //   alert("Hello!");
  // }

    const formData = new FormData();

    //const userId = formData.get("gid");

    

    formData.append("galleryName", galleryName);
    formData.append("galleryCategory", galleryCategory);
    formData.append("galleryLink", galleryLink);
    formData.append("imageGallery", imageGallery);
    formData.append("id", userId);

    //https://dappo.gov.pk/Dappo/gallery_api/insertGallery_api.php/
    
    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    

    try {
      const response = await axios.post(
        "https://dappo.gov.pk/Dappo/gallery_api/insertGallery_api.php/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setGalleryName("");
      setImageGallery([null]);

      //console.log(response.data.message); // You can do something with the response data here
      
      if (response.data.message) {
      setGalleryResult(response.data.message);   
      }
    
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary mb-3"
        data-bs-toggle="modal"
        data-bs-target="#addGallery"
      >
        Add Gallery Image
      </button>

      <div className="modal" id="addGallery">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Gallery</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={handleFormSubmit}>
                <input
                  type="hidden"
                  className="form-control"
                  name="gid"
                  id="gid"
                  value={userId}
                />

                <div className="form-group mb-2">
                  <label htmlFor="">Gallery Content:</label>
                  <input
                    type="text"
                    name="galleryName"
                    id="galleryName"
                    className="form-control"
                    value={galleryName}
                    onChange={handleOffierNameChange}
                    required
                  />
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="">Gallery Category:</label>
                  <select
                    className="form-select"
                    value={galleryCategory}
                    id="galleryCategory"
                    name="galleryCategory"
                    onChange={(e)=> setGalleryCategory(e.target.value)}
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="gallery">Gallery</option>
                    <option value="desktop">Desktop</option>
                  </select>
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="">Gallery Link:</label>
                  <input
                    type="text"
                    name="galleryLink"
                    id="galleryLink"
                    className="form-control"
                    value={galleryLink}
                    onChange={(e) => setGalleryLink(e.target.value)}
                    
                  />
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="image">Upload Image:</label>
                  <input
                    type="file"
                    name="imageGallery"
                    id="imageGallery"
                    className="form-control-file"
                    onChange={handleImageChange}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>

            <div className="modal-footer">
              {galleryResult}
              <p className="bg-info">{galleryResult}</p>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(GalleryForm);
