import React from 'react'

const Map = () => {
  return (
    <div>
      <div class="container-fluid">
				<div className="row mt-5 mb-0">
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3399.5422094058417!2d74.30946851493556!3d31.564175952136285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39191caa07266cd5%3A0x95fd39b86b4ce87c!2sAudit+Complex+(Old+PT%26T+Audit+Building)!5e0!3m2!1sen!2s!4v1502628200611" 
                                                style={{width:"100%",height:"20rem"}} ></iframe>

				</div>
			</div>
    </div>
  )
}

export default React.memo(Map)
