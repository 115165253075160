import React from 'react';
import logoCopy from "../img/logo-white.png";
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBIcon,
  MDBRow
} from 'mdb-react-ui-kit';


export const Footer = () => {
  return (
    <>
    <MDBFooter style={{backgroundColor:'#004D25', position:'relative'}} className='text-center text-md-start text-lg-left text-white mt-2'>
      <MDBContainer className='p-4 ' >
        <MDBRow>
          <MDBCol lg='6' md='6' className='mb-4 mb-md-0 ' >
            <h5 className='text-uppercase text-warning text-center'>About us</h5>
            <div className="d-flex justify-content-start">
            <img src={logoCopy} className="rounded-circle" style={{height:"3rem", marginRight:'1rem'}} />
            <div className='text-justify'>
            <p>Directorate of Accounts, Pakistan Post Office Department, is an attached department of Controller General of Accounts (CGA).</p>
            </div>
            </div>
            
          </MDBCol>

          <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
            <h5 className='text-uppercase mb-0 text-warning ms-3' >Contact us</h5>

            <ul className='list'>
              <li>
              <i className="telephone"></i>            
              <i className="bi-telephone"></i> +92-42-9921-2020
              </li>
              <li>
              <i className="bi-printer"></i> +92-42-9921-2023
              </li>
              <li>
              <i className="bi-envelope-at"></i> contact@dappo.gov.pk
              </li>
              <li>
              <i className="bi-building"></i> 4th Floor, Audit Complex, Syed Mouj Darya Road, Lahore.
              </li>
            </ul>
          </MDBCol>

          <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
            <h5 className='text-uppercase text-warning ms-3'>Quick Links</h5>

            <ul className='list mb-0 '>
              <li>
                <a href='https://www.finance.gov.pk/' className='text-white'>
                  Ministry of Finance
                </a>
              </li>
              <li>
                <a href='https://www.cga.gov.pk/' className='text-white'>
                  Controller General of Accounts
                </a>
              </li>
              <li>
                <a href='https://www.agp.gov.pk/' className='text-white'>
                  Auditor General of Pakistan
                </a>
              </li>
              <li>
                <a href='https://www.agpr.gov.pk/' className='text-white'>
                  AG Pakistan Revenue
                </a>
              </li>
              <li>
                <a href='https://www.pakpost.gov.pk/' className='text-white'>
                  Pakistan Post
                </a>
              </li>
              <li>
                <a href='https://citizenportal.gov.pk' className='text-white'>
                  PMDU / Citizen Portal
                </a>
              </li>
            </ul>
          </MDBCol>

          
        </MDBRow>
      </MDBContainer>

      
      
      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className='text-white' href='https://dappo.gov.pk/'>
          dappo.gov.pk
        </a>
         <span>&nbsp; &nbsp; Developed by: IT Section (DAPPO)</span>
        </div>
      
     
      
    </MDBFooter>
    </>
  );
}